import { useSelector } from '@/api/state';
import { useTranslation } from '@/i18n';
import BulkCsvErrors from '@/ui/components/bulkUploadDownloadModals/BulkCsvErrors';
import BulkCsvWarnings from '@/ui/components/bulkUploadDownloadModals/BulkCsvWarnings';
import { useHashFragment } from '@/utils/customHooks';
import { getRegion } from '@/utils/stateAccessors';
import {
  faCircleCheck,
  faCircleExclamation,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Modal from '@payaca/components/modal/Modal';
import QuickActionsTableCell from '@payaca/components/quickActionsTableCell/QuickActionsTableCell';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import {
  confirmContinueProcessing,
  getCsvUploads,
  getFileDownloadUrl,
} from '@payaca/store/csvUpload/csvUploadActions';
import {
  CsvUpload,
  CsvUploadDataType,
  CsvUploadStatus,
} from '@payaca/types/csvUploadTypes';
import moment from 'moment-timezone';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { GREEN_DARK, RED, YELLOW_DARK } from '@payaca/constants/colours';

import './CsvUploadsPage.sass';
import { downloadFile } from '@/utils/download-file';

type Props = {
  //
};

const POLLING_INTERVAL_MILLIS = 3000;

const CsvUploadsPage: FC<Props> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const pollCsvUploads = () => {
      dispatch(getCsvUploads.request());
    };

    pollCsvUploads();

    const interval = setInterval(() => {
      pollCsvUploads();
    }, POLLING_INTERVAL_MILLIS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const csvUploads = useSelector((state) => {
    return state.csvUpload.store || {};
  });

  const sortedCsvUploads = useMemo(
    () =>
      Object.values(csvUploads).sort(
        (a, b) => b.createdAt?.localeCompare(a?.createdAt) ?? 0
      ),
    [csvUploads]
  );

  const translate = useTranslation();
  const region = useSelector(getRegion);
  const shortDateRegionalFormat = useMemo(
    () => getInternationalDateFormatByRegion(DateFormats.TIMESTAMP, region),
    [region]
  );

  const statusIndicators: Record<CsvUploadStatus, React.ReactNode> = useMemo(
    () => ({
      created: <MiniLoader />,
      uploading: <MiniLoader />,
      uploadAborted: (
        <FontAwesomeIcon icon={faCircleExclamation} color={YELLOW_DARK} />
      ),
      uploadFailed: (
        <FontAwesomeIcon icon={faTriangleExclamation} color={RED} />
      ),
      uploadComplete: <MiniLoader />,
      received: <MiniLoader />,
      validating: <MiniLoader />,
      pendingConfirmation: (
        <FontAwesomeIcon icon={faCircleExclamation} color={YELLOW_DARK} />
      ),
      validationFailed: (
        <FontAwesomeIcon icon={faTriangleExclamation} color={RED} />
      ),
      validationComplete: <MiniLoader />,
      processing: <MiniLoader />,
      processingFailed: (
        <FontAwesomeIcon icon={faTriangleExclamation} color={RED} />
      ),
      complete: <FontAwesomeIcon icon={faCircleCheck} color={GREEN_DARK} />,
    }),
    []
  );

  const [selectedCsvUpload, setSelectedCsvUpload] =
    useState<CsvUpload<CsvUploadDataType> | null>(null);
  const [showCsvIssuesModal, toggleCsvIssuesModal] = useHashFragment('#issues');

  return (
    <>
      <Helmet title={translate('pages.csvUploads.title')}></Helmet>
      <AuthenticatedPageWrapper
        className="csv-uploads-page"
        title={translate('pages.csvUploads.title')}
      >
        <div className="main-container">
          <p>
            <Link to={`/customers#bulk-upload`}>Upload Customers</Link>
          </p>
          <p>
            <Link to={`/items#bulk-upload`}>Upload new Items</Link> /{' '}
            <Link to={`/items#bulk-update`}>Update existing Items</Link>
          </p>
          {/* Uncomment once generic materials import is implemented */}
          {/*
          <p>
            <Link to={`/materials#bulk-upload`}>Upload new Materials</Link> /{' '}
            <Link to={`/materials#bulk-update`}>Update existing Materials</Link>
          </p>
          */}
          <p>
            To upload/update Materials for a particular Supplier, go to their
            Materials list via the <Link to={`/suppliers`}>Suppliers page</Link>
            .
          </p>
          {sortedCsvUploads.length === 0 ? (
            <p>You haven&apos;t uploaded any CSV files yet.</p>
          ) : (
            <table className="data-table">
              <thead>
                <tr>
                  <th>Filename</th>
                  <th>Data type</th>
                  <th colSpan={2}>Status</th>
                  <th>Created at</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {sortedCsvUploads.map((csvUpload) => {
                  const quickActions = [
                    (csvUpload.errors || csvUpload.warnings) &&
                      csvUpload.status !== 'complete' && {
                        actionName: 'View issues',
                        actionBehaviour: () => {
                          setSelectedCsvUpload(csvUpload);
                          toggleCsvIssuesModal();
                        },
                      },
                  ];
                  return (
                    <tr key={csvUpload.id}>
                      <td>
                        <Button
                          styleVariant={ButtonStyleVariant.ANCHOR}
                          onClick={async () => {
                            dispatch(
                              getFileDownloadUrl.request(
                                csvUpload.id,
                                (url) => {
                                  downloadFile(url, csvUpload.fileName);
                                }
                              )
                            );
                          }}
                        >
                          {csvUpload.fileName}
                        </Button>
                      </td>
                      <td>
                        {translate(
                          `csvUploads.dataTypes.${csvUpload.dataType}`
                        )}
                      </td>
                      <td align="center">
                        {statusIndicators[csvUpload.status]}
                      </td>
                      <td>
                        {translate(`csvUploads.statuses.${csvUpload.status}`)}
                      </td>
                      <td>
                        {moment(csvUpload.createdAt).format(
                          shortDateRegionalFormat
                        )}
                      </td>
                      <QuickActionsTableCell
                        recordId={csvUpload.id}
                        quickActions={quickActions.filter(Boolean) as any}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <Modal
          isOpen={showCsvIssuesModal}
          title="CSV Upload issues"
          size="sm"
          onClose={toggleCsvIssuesModal}
          className="bulk-update-modal"
        >
          <div>
            {/* Upload error  */}
            {selectedCsvUpload?.errors && (
              <BulkCsvErrors uploadCsvError={selectedCsvUpload?.errors || []} />
            )}
            {selectedCsvUpload?.warnings && (
              <BulkCsvWarnings
                uploadCsvWarning={selectedCsvUpload?.warnings || []}
                onConfirmContinueUpload={() => {
                  dispatch(
                    confirmContinueProcessing.request(selectedCsvUpload.id)
                  );
                  toggleCsvIssuesModal();
                }}
              />
            )}
          </div>
        </Modal>
      </AuthenticatedPageWrapper>
    </>
  );
};

export default CsvUploadsPage;
