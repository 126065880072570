import React, { FC, ReactNode, useMemo } from 'react';

import { getBrandingColourContext } from '@payaca/helpers/brandingColourContextHelper';
import { getTotalsFromJobLineItems } from '@payaca/helpers/changeProposalHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
  getRegionalTextString,
} from '@payaca/helpers/internationalHelper';
import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import { ChangeProposal } from '@payaca/types/changeProposalTypes';
import {
  Customer,
  CustomerAddress,
  CustomerContact,
} from '@payaca/types/customerTypes';
import { DealAddress } from '@payaca/types/dealTypes';
import { JobContent, JobLineItem } from '@payaca/types/jobContentTypes';
import moment from 'moment-timezone';
import './ChangeProposalDocument.sass';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import ClientDocument from '../clientDocument/ClientDocument';
import DocumentItem from '../clientDocument/DocumentItem';
import DocumentCard from '../clientDocument/DocumentCard';
import InvoiceItem from '../clientDocument/InvoiceItem';
import DocumentAcceptedSignature from '../clientDocument/DocumentAcceptedSignature';
import Alert, { EAlertColour } from '../plAlert/Alert';
import { ClientChangeProposal } from '@payaca/types/clientTypes';
import { TBreadcrumbItem } from '../plBreadcrumb/Breadcrumb';

type Props = {
  documentSenderContext: DocumentSenderContext;
  jobLineItems: JobLineItem[]; // all job line items on deal
  acceptedJobContent: Pick<JobContent, 'jobLineItemIds'>;
  pdfUrl?: string;
  attachments?: any[];
  changeProposals: Pick<
    ClientChangeProposal,
    | 'reference'
    | 'customReference'
    | 'contactId'
    | 'voidedAt'
    | 'expiresAt'
    | 'sentAt'
    | 'acceptedAt'
    | 'declinedAt'
    | 'jobLineItemIds'
    | 'id'
    | 'createdAt'
    | 'signatureImage'
    | 'valueChangeIncTax'
    | 'notes'
    | 'clientV2ProjectsViewURL'
    | 'clientV2ProjectViewURL'
    | 'dealReference'
  >[];
  changeProposalId: number;
  customer: Pick<Customer, 'name'> & {
    addresses: Pick<CustomerAddress, 'address' | 'isBillingAddress'>[];
    contacts: Pick<
      CustomerContact,
      'name' | 'emailAddress' | 'isPrimaryContact' | 'telephoneNumber' | 'id'
    >[];
  };
  siteAddresses: DealAddress[];
  hideItemPrices?: boolean;
  hideVatBreakdown?: boolean;
  CTA?: ReactNode;
  isPreview?: boolean;
};
const ChangeProposalDocument: FC<Props> = ({
  documentSenderContext,
  pdfUrl,
  jobLineItems,
  acceptedJobContent,
  changeProposals,
  changeProposalId,
  customer,
  siteAddresses,
  hideItemPrices = false,
  hideVatBreakdown = false,
  CTA,
  attachments,
  isPreview = false,
}: Props): JSX.Element | null => {
  const brandingColourContext = getBrandingColourContext(
    documentSenderContext.brandColour
  );

  const changeProposal = useMemo(() => {
    return changeProposals.find((x) => x.id == changeProposalId);
  }, [changeProposals, changeProposalId]);

  const contact = useMemo(() => {
    if (!changeProposal) return;
    let c = customer.contacts.find((x) => x.id === changeProposal.contactId);
    if (c) return c;
    c = customer.contacts.find((x) => x.isPrimaryContact);
    if (c) return c;
    return customer.contacts[0];
  }, [customer, changeProposal]);

  const { documentOriginAddressPart1, documentOriginAddressPart2 } =
    useMemo(() => {
      const part1 = [
        documentSenderContext.address?.line1,
        documentSenderContext.address?.line2,
      ].filter((x) => !!x?.length);
      const part2 = [
        documentSenderContext.address?.city,
        documentSenderContext.address?.postcode,
      ].filter((x) => !!x?.length);

      return {
        documentOriginAddressPart1: part1.length ? part1.join(', ') : undefined,
        documentOriginAddressPart2: part2.length ? part2.join(', ') : undefined,
      };
    }, [documentSenderContext]);

  const billingAddress = useMemo(() => {
    const a = customer.addresses.find((x) => x.isBillingAddress);
    if (a) return a;
    return customer.addresses[0];
  }, [customer]);

  const shortDateRegionalFormat = useMemo(
    () =>
      getInternationalDateFormatByRegion(
        DateFormats.SHORT,
        documentSenderContext.region
      ),
    [documentSenderContext]
  );

  const keyInformation = useMemo(() => {
    if (!changeProposal) return [];
    const hd = [
      {
        label: 'Reference',
        value:
          changeProposal.customReference || changeProposal.reference.toString(),
      },
    ];

    if (changeProposal.sentAt) {
      hd.push({
        label: 'Issued',
        value: moment(changeProposal.sentAt).format(shortDateRegionalFormat),
      });
    }

    if (changeProposal.acceptedAt) {
      hd.push({
        label: 'Accepted',
        value: moment(changeProposal.acceptedAt).format(
          shortDateRegionalFormat
        ),
      });
    }

    if (changeProposal.declinedAt) {
      hd.push({
        label: 'Declined',
        value: moment(changeProposal.declinedAt).format(
          shortDateRegionalFormat
        ),
      });
    }

    if (!changeProposal.voidedAt && !!changeProposal.expiresAt) {
      hd.push({
        label: 'Valid until',
        value: moment(changeProposal.expiresAt).format(shortDateRegionalFormat),
      });
    }

    if (changeProposal.voidedAt) {
      hd.push({
        label: 'Void',
        value: moment(changeProposal.voidedAt).format(shortDateRegionalFormat),
      });
    }

    return hd;
  }, [changeProposal, shortDateRegionalFormat]);

  const acceptedJobLineItems = useMemo(() => {
    if (!changeProposal) return [];

    const previousChangeProposals = changeProposals.filter((x) => {
      return (
        x.acceptedAt &&
        !x.voidedAt &&
        new Date(x.acceptedAt) < new Date(changeProposal.createdAt)
      );
    });

    const jliIds = [
      ...acceptedJobContent.jobLineItemIds,
      ...previousChangeProposals.flatMap((x) => x.jobLineItemIds),
    ];

    return jliIds
      .map((x) => jobLineItems.find((y) => y.id == x))
      .filter(
        (x) => x && (x.isSelected || (!x.isMultipleChoice && !x.isOptional))
      ) as JobLineItem[];
  }, [acceptedJobContent, changeProposals, jobLineItems]);

  const changeProposalJobLineItems = useMemo(() => {
    if (!changeProposal) return [];

    return changeProposal.jobLineItemIds
      .map((x: number) => jobLineItems.find((y) => y.id == x))
      .filter(
        (x) => x && (x.isSelected || (!x.isMultipleChoice && !x.isOptional))
      ) as JobLineItem[];
  }, [changeProposal, jobLineItems]);

  const hasAnyReverseChargeVat = useMemo(() => {
    // hide vat if no items have any vat included
    return (
      acceptedJobLineItems.some((jli: JobLineItem) => jli.isReverseChargeVat) ||
      changeProposalJobLineItems.some(
        (jli: JobLineItem) => jli.isReverseChargeVat
      )
    );
  }, [acceptedJobLineItems, changeProposalJobLineItems]);

  const [addedJobLineItems, removedJobLineItems] = useMemo(() => {
    return changeProposalJobLineItems.reduce<[JobLineItem[], JobLineItem[]]>(
      (acc, item) => {
        if (item && item.quantity >= 0) {
          // Added items
          acc[0].push(item);
        } else if (item) {
          // Removed items
          acc[1].push(item);
        }

        return acc;
      },
      [[], []]
    );
  }, [changeProposalJobLineItems]);

  const totals = useMemo(
    () =>
      getTotalsFromJobLineItems([
        ...acceptedJobLineItems,
        ...changeProposalJobLineItems,
      ]),
    [jobLineItems]
  );

  const vatRegionalLabel = useMemo(
    () =>
      getRegionalTextString(
        documentSenderContext.region,
        RegionalStrings.VALUE_ADDED_TAX
      ),
    [documentSenderContext.region]
  );

  const breadcrumbItems = useMemo(() => {
    if (
      !changeProposal ||
      (changeProposal?.clientV2ProjectsViewURL === undefined &&
        changeProposal?.clientV2ProjectViewURL === undefined)
    ) {
      return undefined;
    }

    const _breadcrumbItems: TBreadcrumbItem[] = [];

    if (changeProposal.clientV2ProjectsViewURL) {
      _breadcrumbItems.push({
        label: 'All Projects',
        href: changeProposal.clientV2ProjectsViewURL,
      });
    }

    if (changeProposal.clientV2ProjectViewURL) {
      _breadcrumbItems.push({
        label: `Project #${changeProposal.dealReference} overview`,
        href: changeProposal.clientV2ProjectViewURL,
      });
    }

    _breadcrumbItems.push({
      label: `Change Proposal #${
        changeProposal.customReference || changeProposal.reference.toString()
      }`,
    });

    return _breadcrumbItems;
  }, [changeProposal]);

  if (!changeProposal) return null;

  return (
    <ClientDocument
      hasMaxWidth={!isPreview}
      stickyTopClassName={isPreview ? 'top-24' : undefined}
      breadcrumbItems={breadcrumbItems}
      jobType="Change Proposal"
      pdfUrl={pdfUrl}
      pdfFileName={`change-proposal-${
        changeProposal.customReference || changeProposal.reference
      }.pdf`}
      logoUrl={documentSenderContext.logoUrl}
      siteAddresses={siteAddresses}
      companyName={documentSenderContext.companyName}
      customer={customer as Customer}
      contact={contact}
      keyInformation={keyInformation}
      notes={changeProposal.notes}
      paymentTerms={documentSenderContext.paymentTerms}
      businessTerms={documentSenderContext.businessTerms}
      jobAttachments={attachments}
      contactSection={{
        email: documentSenderContext.emailAddress,
        phoneNumber: documentSenderContext.phoneNumber,
        registeredCountryName: documentSenderContext.registeredCountryName,
        companyRegistrationNumber:
          documentSenderContext.companyRegistrationNumber,
        vatNumber: documentSenderContext.vatNumber,
        vatRegNoRegionalLabel: vatRegionalLabel,
      }}
      brandingColourContext={brandingColourContext}
      documentOriginAddressPart1={documentOriginAddressPart1}
      documentOriginAddressPart2={documentOriginAddressPart2}
      SidebarContent={
        <DocumentCard>
          <table className="w-full text-base">
            <tbody className="text-right">
              {!hideVatBreakdown && (
                <tr>
                  <td className="pr-2">Subtotal:</td>
                  <td className="align-bottom">
                    {currencyPrice(
                      totals.subtotal + totals.markupTotal,
                      documentSenderContext.region
                    )}
                  </td>
                </tr>
              )}
              {totals.discountTotal != 0 && (
                <tr>
                  <td className="pr-2">Discount:</td>
                  <td className="align-bottom">
                    {currencyPrice(
                      -totals.discountTotal,
                      documentSenderContext.region
                    )}
                  </td>
                </tr>
              )}
              {totals.cisTotal !== 0 && (
                <tr>
                  <td className="pr-2">CIS suffered:</td>
                  <td className="align-bottom">
                    {currencyPrice(
                      -totals.cisTotal,
                      documentSenderContext.region
                    )}
                  </td>
                </tr>
              )}
              {!hideVatBreakdown && (
                <tr>
                  <td className="pr-2">{vatRegionalLabel}:</td>
                  <td className="align-bottom">
                    {currencyPrice(
                      totals.vatTotal,
                      documentSenderContext.region
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td className="pr-2">Total payable amount:</td>
                <td className="align-bottom">
                  {currencyPrice(totals.total, documentSenderContext.region)}
                </td>
              </tr>
            </tbody>
          </table>

          <hr className="hr my-4" />
          <table className="w-full text-base">
            <tbody className="text-right font-semibold">
              <tr>
                <td>Change in price:</td>
                <td>
                  {currencyPrice(
                    changeProposal.valueChangeIncTax,
                    documentSenderContext.region
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          {changeProposal.acceptedAt && (
            <>
              <hr className="hr my-4" />

              <DocumentAcceptedSignature
                jobType="Change proposal"
                jobAcceptedAt={changeProposal?.acceptedAt}
                jobContact={contact as CustomerContact}
                acceptedSignatureImageData={changeProposal.signatureImage}
              />
            </>
          )}

          {changeProposal.declinedAt && (
            <>
              <hr className="hr my-4" />

              <Alert colour={EAlertColour.SOFT_RED}>
                Declined on{' '}
                {moment(changeProposal.declinedAt).format(
                  'Do MMMM YYYY [at] HH:mm'
                )}
              </Alert>
            </>
          )}

          {hasAnyReverseChargeVat && (
            <p
              className="mt-2 rounded-lg border border-yellow-200 bg-yellow-100 p-4 text-base text-yellow-800 dark:border-yellow-900 dark:bg-yellow-800/10 dark:text-yellow-500"
              role="alert"
            >
              Customer to account to HMRC for the reverse charge output tax on
              the VAT exclusive price of items marked ‘reverse charge’ at the
              relevant VAT rate as displayed.
            </p>
          )}

          {CTA}
        </DocumentCard>
      }
    >
      <DocumentCard title="Proposed changes">
        <div className="flex flex-col gap-y-6 print:block">
          <DocumentCard.Section
            title="Removed Items"
            icon="minus-square.3"
            iconColourClassName="text-red-500"
            items={removedJobLineItems}
          >
            {(item) => (
              <DocumentItem
                variant={'removed'}
                region={documentSenderContext.region}
                hideItemPrices={hideItemPrices}
                hideVat={hideVatBreakdown}
                isEditable={false}
                item={item}
                attachments={[]}
                isSelected
              />
            )}
          </DocumentCard.Section>
          <DocumentCard.Section
            title="Added Items"
            icon="plus-square.3"
            iconColourClassName="text-teal-500"
            items={addedJobLineItems}
          >
            {(item) => (
              <DocumentItem
                variant={'added'}
                region={documentSenderContext.region}
                hideItemPrices={hideItemPrices}
                hideVat={hideVatBreakdown}
                isEditable={false}
                item={item}
                attachments={[]}
                isSelected
              />
            )}
          </DocumentCard.Section>
        </div>
      </DocumentCard>

      <DocumentCard title="Previously agreed">
        <div className="flex flex-col gap-8 print:block">
          {acceptedJobLineItems.map((item) => (
            <InvoiceItem
              key={item.id}
              item={item}
              region={documentSenderContext.region}
              hideItemPrices={hideItemPrices}
              hideVatBreakdown={hideVatBreakdown}
            />
          ))}
        </div>
      </DocumentCard>
    </ClientDocument>
  );
};

export default ChangeProposalDocument;
