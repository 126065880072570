import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import qs from 'qs';
import LoaderOverlay from '@payaca/components/loaderOverlay/LoaderOverlay';
import { StatusError } from '@/ui/components';
import PayacaLogo from '@/assets/images/oAuth/icon.png';
import { ScopeData } from '@payaca/types/authTypes';
import * as authActions from '@payaca/store/auth/authActions';
import { getAppLogo, getScopeList } from '@/helpers/oAuthHelper';
import './GrantAccessPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';
import { actions as appActions } from '@/api/app';

import Button from '@payaca/components/button/Button';

import { actions as jobActions } from '@/api/jobs';
import { actions as userActions } from '@/api/users';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getUserRoles } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';

type Props = {
  location: any;
  history: any;
};
const GrantAccessPage: FC<Props> = ({
  location,
  history,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [accessError, setAccessError] = useState<{
    status?: number;
    error: string;
    error_description: string;
    error_resolve?: string;
    showButton?: boolean;
  } | null>(null);
  const [appName, setAppName] = useState<any>('');
  const [appScopes, setAppScopes] = useState<any[]>([]);
  const [allowingAuthorise, setAllowingAuthorise] = useState<boolean>(false);
  const [denyingAuthorise, setDenyingAuthorise] = useState<boolean>(false);
  const [statusErrorButton, setStatusErrorButton] = useState<{
    title: string;
    onClick: () => void;
  }>({ title: '', onClick: () => null });

  const userRoles = useSelector(getUserRoles);
  const isAdmin = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_CONNECTION,
    ]);
  }, [userRoles]);

  const previousPathname = useSelector(
    (state: any) => state.app.previousPathname
  );

  useEffect(() => {
    if (!query.path || !query.app || !query.scope) {
      // missing query params - show error
      setAccessError({
        error: 'Invalid url',
        error_description: 'Grant access query is invalid',
      });
    } else if (!isAdmin) {
      // user is not admin - deny access
      setAccessError({
        error: 'User does not have permission',
        error_description: `Connecting with ${query.app} requires you to have a Payaca admin role`,
        error_resolve: 'Try contacting an account admin for this permission',
      });
      setStatusErrorButton({
        title: 'Log in with a different email',
        onClick: () => logout(),
      });
    } else {
      setAppName(decodeURIComponent(query.app));
      // set scopes on page
      const scopes = getScopeList(query.scope);
      setAppScopes(scopes);
    }
    setIsLoading(false);
  }, []);

  const logout = useCallback(() => {
    dispatch(authActions.logout());
    history.push(previousPathname);
  }, [history, previousPathname]);

  const query: any = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }, [location]);

  const grantAccess = (doAllow: boolean) => {
    setAllowingAuthorise(doAllow);
    setDenyingAuthorise(!doAllow);
    const payload = {
      path: query.path,
      scopes: query.scope,
    };
    dispatch(
      authActions.authorise(payload, doAllow, (err: any, resp: any) => {
        if (resp) {
          console.log(
            `Access ${doAllow ? 'allowed' : 'denied'}, redirecting...`
          );
          // @ts-ignore
          window.location.href = resp.redirectUri;
        } else {
          setAccessError(err);
          setAllowingAuthorise(false);
          setDenyingAuthorise(false);
        }
      })
    );
  };

  if (isLoading) {
    return <LoaderOverlay />;
  } else if (accessError) {
    return (
      <StatusError
        title="Authorisation Error"
        status={accessError.status}
        errorName={accessError.error}
        errorDescriptionMessage={accessError.error_description}
        errorResolveMessage={
          accessError.error_resolve ||
          `Try going back to ${appName} and authorising again. If the problem persists, please contact us for help.`
        }
        buttonTitle={statusErrorButton.title}
        buttonOnClick={statusErrorButton.onClick}
      />
    );
  } else {
    return (
      <div className="grant-access">
        <div className="grant-access-content">
          {/* Payaca and 3rd party logos */}
          <div className="logo-wrapper">
            <img
              className="logo"
              src={getAppLogo(appName)}
              alt="3rd party logo"
            />
            <FontAwesomeIcon icon={faExchangeAlt} className="exchange icon" />
            <img className="logo" src={PayacaLogo} alt="Payaca logo" />
          </div>
          <h3>{appName} would like access to your Payaca account</h3>

          {/* Scope details */}
          {appScopes && (
            <div className="app-scopes-wrapper">
              <h4>This will allow {appName} to:</h4>
              <div className="app-scopes">
                {appScopes.map((scope: ScopeData, i: number) => {
                  // TODO: show some sort of scope error if there are no scopes
                  return (
                    <div className="app-scope" key={i}>
                      {scope.icon && (
                        <FontAwesomeIcon
                          icon={scope.icon}
                          className="scope icon"
                        />
                      )}
                      {scope.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <Button
            onClick={() => grantAccess(true)}
            isProcessing={allowingAuthorise}
            isDisabled={allowingAuthorise || denyingAuthorise}
          >
            Allow access
          </Button>
          {/* Deny access button */}
          {/* @ts-ignore */}
          <Button
            onClick={() => grantAccess(false)}
            isProcessing={denyingAuthorise}
            isDisabled={allowingAuthorise || denyingAuthorise}
          >
            Deny access
          </Button>
        </div>
        <div className="grant-access-footer">
          <span onClick={() => logout()} className="logout-action">
            Log in with a different email
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </div>
      </div>
    );
  }
};

export default GrantAccessPage;
