import { useSelector } from '@/api/state';
import { getScheduledEvent } from '@/utils/stateAccessors';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import {
  getTimeDuration,
  roundDateTimeUpToHalfHour,
} from '@payaca/utilities/timeUtilities';
import moment from 'moment-timezone';
import React, { FC, useMemo } from 'react';
import './ScheduledEventKeyInformationHeader.sass';

type Props = {
  scheduledEvent: ScheduledEvent;
};
const ScheduledEventKeyInformationHeader: FC<Props> = ({
  scheduledEvent,
}: Props): JSX.Element | null => {
  const { eventBeginsAt, eventEndsAt } = useMemo(() => {
    const eventBegins =
      scheduledEvent?.beginAt || roundDateTimeUpToHalfHour(new Date()).toDate();
    return {
      eventBeginsAt: eventBegins,
      eventEndsAt:
        scheduledEvent?.endAt || moment(eventBegins).add(1, 'hours').toDate(),
    };
  }, [scheduledEvent]);

  const duration = useMemo<any>(
    () => getTimeDuration(eventBeginsAt, eventEndsAt),
    [eventBeginsAt, eventEndsAt]
  );

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const timestampShortDateRegionalFormat = useMemo(
    () => getInternationalDateFormatByRegion(DateFormats.SHORT, account.region),
    [account]
  );

  const startsAndFinishesOnSameDay = useMemo(() => {
    return moment(scheduledEvent.beginAt).isSame(scheduledEvent.endAt, 'day');
  }, [scheduledEvent]);

  const startTime = useMemo(() => {
    return moment(scheduledEvent.beginAt).format(`h:mma`);
  }, [scheduledEvent]);

  const startDate = useMemo(() => {
    return moment(scheduledEvent.beginAt).format(
      timestampShortDateRegionalFormat
    );
  }, [scheduledEvent, timestampShortDateRegionalFormat]);

  const endTime = useMemo(() => {
    return moment(scheduledEvent.endAt).format(`h:mma`);
  }, [scheduledEvent]);

  const endDate = useMemo(() => {
    return moment(scheduledEvent.endAt).format(
      timestampShortDateRegionalFormat
    );
  }, [scheduledEvent, timestampShortDateRegionalFormat]);

  const startDay = useMemo(() => {
    return moment(scheduledEvent.beginAt).format('DD');
  }, [scheduledEvent]);
  const startMonth = useMemo(() => {
    return moment(scheduledEvent.beginAt).format('MMM');
  }, [scheduledEvent]);

  return (
    <div className={'scheduled-event-key-information-header flex-container'}>
      <div className="start-date">
        <span className="day">{startDay}</span>
        <span className="month">{startMonth}</span>
      </div>
      <div className="name-duration-container">
        <h1>{scheduledEvent.name}</h1>
        <div className="duration-container flex-container flex-center">
          <div className="time-date">
            <div className="time">
              <strong>{startTime}</strong>
            </div>
            {!startsAndFinishesOnSameDay && (
              <div className="date">
                <small>{startDate}</small>
              </div>
            )}
          </div>
          <span>-</span>
          <div className="time-date">
            <div className="time">
              <strong>{endTime}</strong>
            </div>
            {!startsAndFinishesOnSameDay && (
              <div className="date">
                <small>{endDate}</small>
              </div>
            )}
          </div>
          <span className="duration">
            ({roundToDecimalPlaces(duration.value)} {duration.label})
          </span>
        </div>
      </div>
    </div>
  );
};
export default ScheduledEventKeyInformationHeader;
