import { FC, useEffect, useState } from 'react';
import { format as formatDate } from 'date-fns';
import Button from '@payaca/components/plButton/Button';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import AdvancedTable from '@payaca/components/plAdvancedTable/AdvancedTable';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import { Address } from '@payaca/types/locationTypes';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import Badge from '@payaca/components/plBadge/Badge';
import { useHashFragment } from '../../../../utils/customHooks';
import ConnectionApplicationChooseInstallationModal from './ConnectionApplicationChooseInstallationModal';
import { PendingEnaApplicationStatuses } from '@payaca/types/connectionApplication';

import UntitledLoading from '@payaca/components/plUntitledLoading/UntitledLoading';
import { ConnectionApplication } from '@/gql/graphql';
import { getApplicationStatusBadgeColour } from '@/ui/pages/connectionApplicationPage/utils';

const willRefresh = (
  application: Pick<
    ConnectionApplication,
    'connectionApplicationStatus' | 'status'
  >
): boolean => {
  const possibleStatuses: string[] = PendingEnaApplicationStatuses;
  return (
    (application.connectionApplicationStatus &&
      possibleStatuses.includes(application.connectionApplicationStatus)) ||
    application.status === 'pending'
  );
};

const CompliancePanel: FC = () => {
  const [showChooseInstallationModal, toggleChooseInstallationModal] =
    useHashFragment('#choose-installation');
  const [shouldRefresh, setShouldRefresh] = useState(false);
  /**
   * Router
   */
  const { dealId } = useParams<{ dealId: string }>();
  const history = useHistory();

  /**
   * Queries
   */
  const { data: projectOverview } = useGetProjectOverview(
    +dealId,
    shouldRefresh
  );

  const hasPendingApplication =
    !!projectOverview?.project.connectionApplications?.find((app) =>
      willRefresh(app)
    );
  useEffect(
    () => setShouldRefresh(hasPendingApplication),
    [hasPendingApplication]
  );

  const pollingForUpdates = hasPendingApplication
    ? 'No need to refresh, we are polling for updates. '
    : '';

  return (
    <div className="@container">
      <AdvancedTable
        uniqueKey="id"
        data={projectOverview?.project?.connectionApplications || []}
        headerContent={{
          heading: 'DNO applications',
          subHeading: `${pollingForUpdates}If an application needs manual approval from the DNO, status changes may not be visible for up to an hour.`,
          buttons: (
            <>
              <Button
                onClick={toggleChooseInstallationModal}
                size={EBtnSize.Small}
              >
                Create application
              </Button>
            </>
          ),
        }}
        onClickRow={(row) =>
          history.push(
            `/deals/${dealId}/compliance/connection-application/${row.id}`
          )
        }
        emptyText="No applications"
      >
        <AdvancedTable.Column
          header="Type"
          field="data"
          render={(data, row) => {
            return (
              <Link
                to={`/deals/${dealId}/compliance/connection-application/${row.id}`}
              >
                {(data?.['lcts'] as string[] | undefined)?.join(', ') || 'HP'}
              </Link>
            );
          }}
        />
        <AdvancedTable.Column
          header="Address"
          field="data"
          render={(data) => {
            const address = data?.['installerCustomerDetails']?.[
              'mainAddress'
            ] as
              | Pick<
                  Address,
                  'line1' | 'line2' | 'city' | 'country' | 'postcode'
                >
              | undefined;

            if (!address) {
              return '---';
            }

            const fullAddress = [
              address.line1,
              address.line2,
              address.city,
              address.country,
              address.postcode,
            ]
              .filter((i) => !!i)
              .join(', ');

            return (
              <Tooltip
                tooltipContent={fullAddress}
                className="@screen-lg:max-w-[400px] max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap"
              >
                {fullAddress}
              </Tooltip>
            );
          }}
        />
        <AdvancedTable.Column
          header="Created"
          field="createdAt"
          render={(createdAt) => {
            return (
              <Tooltip tooltipContent={new Date(createdAt).toLocaleString()}>
                {formatDate(createdAt, 'dd MMM `yy')}
              </Tooltip>
            );
          }}
        />
        <AdvancedTable.Column
          header="Created by"
          field="createdBy"
          render={(createdBy) => createdBy?.fullName || ''}
        />
        <AdvancedTable.Column
          header="Submitted"
          field="submittedAt"
          render={(submittedAt) => {
            if (!submittedAt) return '';

            return (
              <Tooltip tooltipContent={new Date(submittedAt).toLocaleString()}>
                {formatDate(submittedAt, 'dd MMM `yy')}
              </Tooltip>
            );
          }}
        />
        <AdvancedTable.Column
          header="Submitted by"
          field="submittedBy"
          render={(submittedBy) => submittedBy?.fullName || ''}
        />
        <AdvancedTable.Column<ConnectionApplication, 'status'>
          header="Status"
          field="status"
          render={(status, row) => {
            const enaStatus = row.connectionApplicationStatus;
            return (
              <Badge
                colour={getApplicationStatusBadgeColour(status)}
                variant="soft"
                className="capitalize"
              >
                <SelfUpdatingWrapper isRefreshing={willRefresh(row)}>
                  {status}
                  {enaStatus ? ` (${enaStatus})` : ''}
                </SelfUpdatingWrapper>
              </Badge>
            );
          }}
        />
      </AdvancedTable>
      <ConnectionApplicationChooseInstallationModal
        isOpen={showChooseInstallationModal}
        onClose={toggleChooseInstallationModal}
      />
    </div>
  );
};

const SelfUpdatingWrapper: FC<{
  isRefreshing: boolean;
  children: React.ReactNode;
}> = ({ isRefreshing, children }) => {
  if (!isRefreshing) return children;
  return (
    <Tooltip
      tooltipContent="Monitoring for updates..."
      className="flex items-center"
    >
      <UntitledLoading className="mr-2 w-4" />
      {children}
    </Tooltip>
  );
};

export default CompliancePanel;
