import BasicField from '../basicField/BasicField';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Search } from 'react-iconly';

import './VariableInsertControl.sass';

type TProps<TVariableType extends string> = {
  availableVariables: TVariableType[];
  variableReadableNameMap?: { [key in TVariableType as string]?: string };
  variableEnabledCopy: string[];
  enableVariableSearch?: boolean;
  children: (props: {
    setEventHandlers: (eventHandlers: {
      insertText: (textToInsert: string) => void;
    }) => void;
  }) => React.ReactNode;
  onUsedVariablesChange?: (usedVariables: TVariableType[]) => void;
};

const VariableInsertControl = <TVariableType extends string>({
  availableVariables,
  variableEnabledCopy,
  variableReadableNameMap,
  enableVariableSearch,
  onUsedVariablesChange,
  children,
}: TProps<TVariableType>) => {
  const [eventHandlers, setEventHandlers] = useState<{
    insertText: (textToInsert: string) => void;
  }>({
    insertText: (textToInsert: string) => null,
  });
  const [searchTerm, setSearchTerm] = useState<string>();

  const usedVariables: TVariableType[] = useMemo(() => {
    return (
      variableEnabledCopy
        .flatMap((x) => {
          return x ? x.match(/\[.*?\]/g) : [];
        })
        /* eslint-disable-next-line */
        .map((x) => x?.replace(/[\[\]']+/g, '') as TVariableType)
        .filter(
          (x, i, arr) =>
            !!x && availableVariables.includes(x) && arr.indexOf(x) === i
        ) as TVariableType[]
    );
  }, [availableVariables, variableEnabledCopy]);

  useCallback(() => {
    onUsedVariablesChange?.(usedVariables);
  }, [usedVariables]);

  const sortedVariables = useMemo(() => {
    return availableVariables.sort().filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
  }, [usedVariables, availableVariables]);

  const mappedVariables = sortedVariables
    .map((a) => ({
      value: a,
      readableName: variableReadableNameMap?.[a],
      isUsed: usedVariables?.includes(a),
    }))
    .filter((x) => {
      if (!searchTerm) {
        return true;
      }
      return (
        x.value.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
        (x.readableName &&
          x.readableName
            .toString()
            .toLowerCase()
            .indexOf(searchTerm.toLowerCase()) > -1)
      );
    });

  return (
    <div className="variable-insert-control">
      <div className="input">{children({ setEventHandlers })}</div>
      <div className="variable-list-and-search-container">
        <span className="available-variables-label">Available variables</span>
        {enableVariableSearch && (
          <BasicField
            name="searchTerm"
            value={searchTerm}
            onChange={(value: { [key: string]: any }) => {
              setSearchTerm(value.searchTerm);
            }}
            iconBefore={<Search set="light" size={18} />}
            additionalInputProps={{
              placeholder: 'Search...',
            }}
          />
        )}
        <div className="variable-list">
          <ul>
            {mappedVariables.map((x) => {
              return (
                <li
                  key={x.value}
                  onClick={() => {
                    setSearchTerm(undefined);
                    eventHandlers.insertText(`[${x.value}]`);
                  }}
                  className={`${x.isUsed ? 'used' : ''}`}
                >
                  <div>{x.readableName || x.value}</div>
                  {x.readableName && (
                    <div>
                      <small>{x.value}</small>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VariableInsertControl;
