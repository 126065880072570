import React, { FC, useMemo } from 'react';
import { DayHeaderContentArg } from '@fullcalendar/react';

import moment from 'moment-timezone';

import './DayHeader.sass';

type Props = {
  dayHeaderContentArg: DayHeaderContentArg;
};
const DayHeader: FC<Props> = ({ dayHeaderContentArg }: Props): JSX.Element => {
  const momentDate = useMemo(() => {
    return moment(dayHeaderContentArg.date);
  }, [dayHeaderContentArg.date]);

  // don't use account regional date for these as this messes up the day labelling
  const dayOfWeekShort = useMemo(() => {
    return (
      <span className="day-of-week-short">
        {momentDate.tz('Europe/London').format('ddd')}
      </span>
    );
  }, [momentDate]);

  const dayOfWeekLong = useMemo(() => {
    return (
      <span className="day-of-week-long">
        {momentDate.tz('Europe/London').format('dddd')}
      </span>
    );
  }, [momentDate]);

  const dayNumber = useMemo(() => {
    return (
      <span className="day-number">
        {momentDate.tz('Europe/London').format('D')}
      </span>
    );
  }, [momentDate]);

  return (
    <div className={`day-header ${dayHeaderContentArg.view.type}`}>
      {['dayGridMonth', 'timeGridWeek'].includes(
        dayHeaderContentArg.view.type
      ) && dayOfWeekShort}
      {['timeGridWeek'].includes(dayHeaderContentArg.view.type) && dayNumber}
      {['timeGridDay'].includes(dayHeaderContentArg.view.type) && dayOfWeekLong}
      {['listWeek'].includes(dayHeaderContentArg.view.type) && (
        <div className="flex-container flex-center">
          <span className="flex-container flex-center flex-grow">
            {dayOfWeekLong}
          </span>
          <span className="date flex-container flex-center">
            {dayNumber}
            {momentDate.format('MMM YYYY')}
          </span>
        </div>
      )}
    </div>
  );
};

export default DayHeader;
