import {
  ActionConfigHelperType,
  AutomationActionTypes,
  AutomationEntityTypes,
  AutomationTriggerEvents,
  DealProgressionActionConfig,
  EmailNotificationActionConfig,
  SMSActionConfig,
  automationTriggerEvents,
} from '@payaca/types/automationTypes';
import { SubscriptionLevels } from '@payaca/permissions/default-role.config';
import { TaggableEntityType } from '@payaca/types/tagTypes';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import {
  VALID_EMAIL_REGEX,
  VALID_PHONE_NUMBER_REGEX_STRICT,
} from '@payaca/constants';

export const automationEntityTypeSubscriptionMap: {
  [key in AutomationEntityTypes]?: SubscriptionLevels;
} = {
  [AutomationEntityTypes.ESTIMATE]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.QUOTE]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.PROPOSAL]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.INVOICE]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.EVENT]: SubscriptionLevels.SOLO,
  /*[AutomationEntityTypes.DOCUMENT]: SubscriptionLevels.TEAM,
  [AutomationEntityTypes.FORM]: SubscriptionLevels.TEAM,
  [AutomationEntityTypes.DEAL]: SubscriptionLevels.TEAM,
  [AutomationEntityTypes.ITEM]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.ITEM_GROUP]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.USER]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.CUSTOMER]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.ADDRESS]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.TAG]: SubscriptionLevels.SOLO,*/
};

export const automationEntityTypeTriggerEventMap: {
  [key in AutomationEntityTypes]?: AutomationTriggerEvents[];
} = {
  [AutomationEntityTypes.DEAL]: [
    AutomationTriggerEvents.TAGGED,
    AutomationTriggerEvents.ADDED_TO_PIPELINE,
    AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED,
    AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_YESTERDAY,
    AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_2_DAYS_AGO,
    AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_3_DAYS_AGO,
    AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_4_DAYS_AGO,
    AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_5_DAYS_AGO,
    AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_6_DAYS_AGO,
    AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_7_DAYS_AGO,
    AutomationTriggerEvents.ASSIGNED,
  ],
  [AutomationEntityTypes.ESTIMATE]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.SENT,
    AutomationTriggerEvents.ACCEPTED,
    AutomationTriggerEvents.DECLINED,
    AutomationTriggerEvents.EXPIRES_IN_28_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_21_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_14_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_7_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_3_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_1_DAY,
    AutomationTriggerEvents.EXPIRES_TODAY,
    AutomationTriggerEvents.EXPIRED_YESTERDAY,
    AutomationTriggerEvents.EXPIRED_3_DAYS_AGO,
    AutomationTriggerEvents.EXPIRED_7_DAYS_AGO,
    /*AutomationTriggerEvents.DECLINED,
    AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ASSIGNED,
    AutomationTriggerEvents.ARCHIVED,*/
  ],
  [AutomationEntityTypes.QUOTE]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.SENT,
    AutomationTriggerEvents.ACCEPTED,
    AutomationTriggerEvents.DECLINED,
    AutomationTriggerEvents.EXPIRES_IN_28_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_21_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_14_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_7_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_3_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_1_DAY,
    AutomationTriggerEvents.EXPIRES_TODAY,
    AutomationTriggerEvents.EXPIRED_YESTERDAY,
    AutomationTriggerEvents.EXPIRED_3_DAYS_AGO,
    AutomationTriggerEvents.EXPIRED_7_DAYS_AGO,
    /*AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ASSIGNED,
    AutomationTriggerEvents.DUE,
    AutomationTriggerEvents.DECLINED,
    AutomationTriggerEvents.ARCHIVED,*/
  ],
  [AutomationEntityTypes.PROPOSAL]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.SENT,
    AutomationTriggerEvents.ACCEPTED,
    AutomationTriggerEvents.DECLINED,
    AutomationTriggerEvents.EXPIRES_IN_28_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_21_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_14_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_7_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_3_DAYS,
    AutomationTriggerEvents.EXPIRES_IN_1_DAY,
    AutomationTriggerEvents.EXPIRES_TODAY,
    AutomationTriggerEvents.EXPIRED_YESTERDAY,
    AutomationTriggerEvents.EXPIRED_3_DAYS_AGO,
    AutomationTriggerEvents.EXPIRED_7_DAYS_AGO,
    /*AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ASSIGNED,
    AutomationTriggerEvents.DUE,
    AutomationTriggerEvents.DECLINED,
    AutomationTriggerEvents.ARCHIVED,*/
  ],
  [AutomationEntityTypes.INVOICE]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.SENT,
    AutomationTriggerEvents.ASSIGNED,
    AutomationTriggerEvents.DUE_IN_7_DAYS,
    AutomationTriggerEvents.DUE_IN_3_DAYS,
    AutomationTriggerEvents.DUE_IN_1_DAY,
    AutomationTriggerEvents.DUE_TODAY,
    AutomationTriggerEvents.DUE_YESTERDAY,
    AutomationTriggerEvents.DUE_3_DAYS_AGO,
    AutomationTriggerEvents.DUE_7_DAYS_AGO,
    AutomationTriggerEvents.FULLY_PAID,
    /*AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ASSIGNED,
    AutomationTriggerEvents.DUE,
    AutomationTriggerEvents.OVERDUE,
    AutomationTriggerEvents.ARCHIVED,*/
  ],
  [AutomationEntityTypes.EVENT]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.TAGGED,
    AutomationTriggerEvents.EVENT_TASK_STATUS_CHANGED,
    AutomationTriggerEvents.STARTS_IN_1_MONTH,
    AutomationTriggerEvents.STARTS_IN_21_DAYS,
    AutomationTriggerEvents.STARTS_IN_14_DAYS,
    AutomationTriggerEvents.STARTS_IN_7_DAYS,
    AutomationTriggerEvents.STARTS_IN_3_DAYS,
    AutomationTriggerEvents.STARTS_IN_1_DAY,
    AutomationTriggerEvents.STARTS_TODAY,
    AutomationTriggerEvents.STARTS_NOW,
    AutomationTriggerEvents.FINISHES_NOW,
    AutomationTriggerEvents.FINISHES_TODAY,
    AutomationTriggerEvents.FINISHED_YESTERDAY,
    AutomationTriggerEvents.FINISHED_3_DAYS_AGO,
    AutomationTriggerEvents.FINISHED_7_DAYS_AGO,
    AutomationTriggerEvents.FINISHED_1_MONTH_AGO,
    AutomationTriggerEvents.FINISHED_11_MONTHS_AGO,
    AutomationTriggerEvents.FINISHED_1_YEAR_AGO,
    /*AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ASSIGNED,
    AutomationTriggerEvents.TAGGED,*/
  ],
  /*
  [AutomationEntityTypes.DOCUMENT]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.COMPLETED,
    AutomationTriggerEvents.SENT,
    AutomationTriggerEvents.DELETED,
  ],
  [AutomationEntityTypes.FORM]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.SENT,
    AutomationTriggerEvents.STARTED,
    AutomationTriggerEvents.COMPLETED,
  ],
  [AutomationEntityTypes.ITEM]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ARCHIVED,
  ],
  [AutomationEntityTypes.ITEM_GROUP]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ARCHIVED,
  ],
  [AutomationEntityTypes.USER]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ARCHIVED,
  ],
  [AutomationEntityTypes.CUSTOMER]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ARCHIVED,
    AutomationTriggerEvents.TAGGED,
  ],
  [AutomationEntityTypes.ADDRESS]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ARCHIVED,
  ],
  [AutomationEntityTypes.TAG]: [
    AutomationTriggerEvents.CREATED,
    AutomationTriggerEvents.UPDATED,
    AutomationTriggerEvents.ARCHIVED,
  ],*/
};

export const automationEntityActionTypeMap: {
  [key in AutomationEntityTypes]?: AutomationActionTypes[];
} = {
  [AutomationEntityTypes.DEAL]: [
    AutomationActionTypes.EMAIL_NOTIFICATION,
    AutomationActionTypes.SMS_NOTIFICATION,
    AutomationActionTypes.DEAL_PROGRESSION,
  ],
  [AutomationEntityTypes.EVENT]: [
    AutomationActionTypes.EMAIL_NOTIFICATION,
    AutomationActionTypes.SMS_NOTIFICATION,
    AutomationActionTypes.DEAL_PROGRESSION,
  ],
  [AutomationEntityTypes.ESTIMATE]: [
    AutomationActionTypes.EMAIL_NOTIFICATION,
    AutomationActionTypes.SMS_NOTIFICATION,
    AutomationActionTypes.DEAL_PROGRESSION,
  ],
  [AutomationEntityTypes.QUOTE]: [
    AutomationActionTypes.EMAIL_NOTIFICATION,
    AutomationActionTypes.SMS_NOTIFICATION,
    AutomationActionTypes.DEAL_PROGRESSION,
  ],
  [AutomationEntityTypes.PROPOSAL]: [
    AutomationActionTypes.EMAIL_NOTIFICATION,
    AutomationActionTypes.SMS_NOTIFICATION,
    AutomationActionTypes.DEAL_PROGRESSION,
  ],
  [AutomationEntityTypes.INVOICE]: [
    AutomationActionTypes.EMAIL_NOTIFICATION,
    AutomationActionTypes.SMS_NOTIFICATION,
    AutomationActionTypes.DEAL_PROGRESSION,
    //AutomationActionTypes.QUICKBOOKS_ADD_INVOICE,
    //AutomationActionTypes.XERO_ADD_INVOICE,
  ],
};

export const automationRealtimeTriggers: AutomationTriggerEvents[] = [
  AutomationTriggerEvents.STARTS_IN_1_HOUR,
  AutomationTriggerEvents.STARTS_NOW,
  AutomationTriggerEvents.FINISHES_NOW,
];

export const automationDailyTriggers: AutomationTriggerEvents[] = [
  AutomationTriggerEvents.DUE_IN_7_DAYS,
  AutomationTriggerEvents.DUE_IN_3_DAYS,
  AutomationTriggerEvents.DUE_IN_1_DAY,
  AutomationTriggerEvents.DUE_TODAY,
  AutomationTriggerEvents.DUE_YESTERDAY,
  AutomationTriggerEvents.DUE_3_DAYS_AGO,
  AutomationTriggerEvents.DUE_7_DAYS_AGO,
  AutomationTriggerEvents.EXPIRES_IN_28_DAYS,
  AutomationTriggerEvents.EXPIRES_IN_21_DAYS,
  AutomationTriggerEvents.EXPIRES_IN_14_DAYS,
  AutomationTriggerEvents.EXPIRES_IN_7_DAYS,
  AutomationTriggerEvents.EXPIRES_IN_3_DAYS,
  AutomationTriggerEvents.EXPIRES_IN_1_DAY,
  AutomationTriggerEvents.EXPIRES_TODAY,
  AutomationTriggerEvents.EXPIRED_YESTERDAY,
  AutomationTriggerEvents.EXPIRED_3_DAYS_AGO,
  AutomationTriggerEvents.EXPIRED_7_DAYS_AGO,
  AutomationTriggerEvents.STARTS_IN_1_MONTH,
  AutomationTriggerEvents.STARTS_IN_21_DAYS,
  AutomationTriggerEvents.STARTS_IN_14_DAYS,
  AutomationTriggerEvents.STARTS_IN_7_DAYS,
  AutomationTriggerEvents.STARTS_IN_3_DAYS,
  AutomationTriggerEvents.STARTS_IN_1_DAY,
  AutomationTriggerEvents.STARTS_TODAY,
  AutomationTriggerEvents.FINISHES_TODAY,
  AutomationTriggerEvents.FINISHED_YESTERDAY,
  AutomationTriggerEvents.FINISHED_3_DAYS_AGO,
  AutomationTriggerEvents.FINISHED_7_DAYS_AGO,
  AutomationTriggerEvents.FINISHED_1_MONTH_AGO,
  AutomationTriggerEvents.FINISHED_1_YEAR_AGO,
];

export const automationTaggableEntityTypeMap: {
  [key in TaggableEntityType]?: AutomationEntityTypes;
} = {
  [TaggableEntityType.SCHEDULED_EVENT]: AutomationEntityTypes.EVENT,
  [TaggableEntityType.DEAL]: AutomationEntityTypes.DEAL,
};

export const automationSoloTriggerEvents = [
  AutomationTriggerEvents.DUE_IN_3_DAYS,
  AutomationTriggerEvents.DUE_TODAY,
  AutomationTriggerEvents.DUE_3_DAYS_AGO,
  AutomationTriggerEvents.DUE_7_DAYS_AGO,
];

export const automationRealtimeTriggerQueryMap: {
  [key in AutomationTriggerEvents]?: [string, number, string];
} = {
  [AutomationTriggerEvents.DUE_IN_7_DAYS]: [
    AutomationDataAccessType.INVOICE_DUEAT,
    7,
    'days',
  ],
  [AutomationTriggerEvents.DUE_IN_3_DAYS]: [
    AutomationDataAccessType.INVOICE_DUEAT,
    3,
    'days',
  ],
  [AutomationTriggerEvents.DUE_IN_1_DAY]: [
    AutomationDataAccessType.INVOICE_DUEAT,
    1,
    'days',
  ],
  [AutomationTriggerEvents.DUE_TODAY]: [
    AutomationDataAccessType.INVOICE_DUEAT,
    0,
    'days',
  ],
  [AutomationTriggerEvents.DUE_YESTERDAY]: [
    AutomationDataAccessType.INVOICE_DUEAT,
    -1,
    'days',
  ],
  [AutomationTriggerEvents.DUE_3_DAYS_AGO]: [
    AutomationDataAccessType.INVOICE_DUEAT,
    -3,
    'days',
  ],
  [AutomationTriggerEvents.DUE_7_DAYS_AGO]: [
    AutomationDataAccessType.INVOICE_DUEAT,
    -7,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRES_IN_28_DAYS]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    28,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRES_IN_21_DAYS]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    21,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRES_IN_14_DAYS]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    14,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRES_IN_7_DAYS]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    7,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRES_IN_3_DAYS]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    3,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRES_IN_1_DAY]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    1,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRES_TODAY]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    0,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRED_YESTERDAY]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    -1,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRED_3_DAYS_AGO]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    -3,
    'days',
  ],
  [AutomationTriggerEvents.EXPIRED_7_DAYS_AGO]: [
    AutomationDataAccessType.PROPOSAL_VALIDUNTIL,
    -7,
    'days',
  ],
  [AutomationTriggerEvents.STARTS_IN_1_MONTH]: [
    AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
    1,
    'month',
  ],
  [AutomationTriggerEvents.STARTS_IN_21_DAYS]: [
    AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
    21,
    'days',
  ],
  [AutomationTriggerEvents.STARTS_IN_14_DAYS]: [
    AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
    14,
    'days',
  ],
  [AutomationTriggerEvents.STARTS_IN_7_DAYS]: [
    AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
    7,
    'days',
  ],
  [AutomationTriggerEvents.STARTS_IN_3_DAYS]: [
    AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
    3,
    'days',
  ],
  [AutomationTriggerEvents.STARTS_IN_1_DAY]: [
    AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
    1,
    'days',
  ],
  [AutomationTriggerEvents.STARTS_IN_1_HOUR]: [
    AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
    1,
    'hours',
  ],
  [AutomationTriggerEvents.STARTS_NOW]: [
    AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
    0,
    'hours',
  ],
  [AutomationTriggerEvents.STARTS_TODAY]: [
    AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT,
    0,
    'days',
  ],
  [AutomationTriggerEvents.FINISHES_NOW]: [
    AutomationDataAccessType.SCHEDULEDEVENT_ENDAT,
    0,
    'days',
  ],
  [AutomationTriggerEvents.FINISHES_TODAY]: [
    AutomationDataAccessType.SCHEDULEDEVENT_ENDAT,
    0,
    'days',
  ],
  [AutomationTriggerEvents.FINISHED_YESTERDAY]: [
    AutomationDataAccessType.SCHEDULEDEVENT_ENDAT,
    -1,
    'days',
  ],
  [AutomationTriggerEvents.FINISHED_3_DAYS_AGO]: [
    AutomationDataAccessType.SCHEDULEDEVENT_ENDAT,
    -3,
    'days',
  ],
  [AutomationTriggerEvents.FINISHED_7_DAYS_AGO]: [
    AutomationDataAccessType.SCHEDULEDEVENT_ENDAT,
    -7,
    'days',
  ],
  [AutomationTriggerEvents.FINISHED_1_MONTH_AGO]: [
    AutomationDataAccessType.SCHEDULEDEVENT_ENDAT,
    -1,
    'month',
  ],
  [AutomationTriggerEvents.FINISHED_1_YEAR_AGO]: [
    AutomationDataAccessType.SCHEDULEDEVENT_ENDAT,
    -1,
    'year',
  ],
};

export const automationDataAccessTypeReadableNameMap: {
  [key in AutomationDataAccessType]?: string;
} = {
  [AutomationDataAccessType.INVOICE_DUEAT]: 'Invoice due at',
  [AutomationDataAccessType.PROPOSAL_VALIDUNTIL]: 'Proposal valid until',
  [AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT]: 'Event begin at',
  [AutomationDataAccessType.SCHEDULEDEVENT_ENDAT]: 'Event end at',

  [AutomationDataAccessType.CUSTOMER_NAME]: 'Customer name',
  [AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_NAME]:
    'Customer contact name',
  [AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS]:
    'Customer contact email',
  [AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER]:
    'Customer contact phone number',

  [AutomationDataAccessType.ACCOUNT_COMPANYNAME]: 'My company name',
  [AutomationDataAccessType.ACCOUNT_VATNUMBER]: 'My VAT number',
  [AutomationDataAccessType.ACCOUNT_ACCOUNTNAME]: 'My bank account name',
  [AutomationDataAccessType.ACCOUNT_ACCOUNTNUMBER]: 'My bank account number',
  [AutomationDataAccessType.ACCOUNT_SORTCODE]: 'My bank sort code',
  [AutomationDataAccessType.ACCOUNT_GASSAFEREGNUMBER]: 'My Gas Safe reg number',
  [AutomationDataAccessType.ACCOUNT_NICEICREGNUMBER]: 'My NICEIC reg number',
  [AutomationDataAccessType.ACCOUNT_EMAILADDRESS]: 'My company email',
  [AutomationDataAccessType.ACCOUNT_CONTACTNUMBER]: 'My company phone number',
  [AutomationDataAccessType.ACCOUNT_PAYMENTTERMS]: 'My payment terms',
  [AutomationDataAccessType.ACCOUNT_BUSINESSNOTES]: 'My business notes',

  [AutomationDataAccessType.DEAL_REFERENCE]: 'Project ref',
  [AutomationDataAccessType.DEAL_DESCRIPTION]: 'Project description',
  [AutomationDataAccessType.DEAL_CREATEDAT]: 'Project created at',
  [AutomationDataAccessType.DEAL_PIPELINESTAGE]: 'Project pipeline stage',
  [AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE]: 'Project custom field',

  [AutomationDataAccessType.USER_FIRSTNAME]: 'User firstname',
  [AutomationDataAccessType.USER_LASTNAME]: 'User lastname',
  [AutomationDataAccessType.USER_EMAILADDRESS]: 'User email',
  [AutomationDataAccessType.USER_SYSTEMROLE]: 'User system role',
  [AutomationDataAccessType.USER_CONTACTNUMBER]: 'User phone number',

  [AutomationDataAccessType.SCHEDULEDEVENT_NAME]: 'Event name',
  [AutomationDataAccessType.SCHEDULEDEVENT_DESCRIPTION]: 'Event description',
  [AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT_FORMATTEDDATE]:
    'Event begin at date',
  [AutomationDataAccessType.SCHEDULEDEVENT_BEGINAT_FORMATTEDTIME]:
    'Event begin at time',
  [AutomationDataAccessType.SCHEDULEDEVENT_ENDAT_FORMATTEDDATE]:
    'Event end at date',
  [AutomationDataAccessType.SCHEDULEDEVENT_ENDAT_FORMATTEDTIME]:
    'Event end at time',
  [AutomationDataAccessType.SCHEDULEDEVENT_LOCATION_ADDRESS_STRING]:
    'Event location',
  [AutomationDataAccessType.SCHEDULEDEVENT_TASKSTATUS]: 'Event task status',
  [AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_NAME_ARRAYSTRING]:
    'Event assigned User names',
  [AutomationDataAccessType.SCHEDULEDEVENT_LOCATION_CONTACTS_EMAILADDRESS_ARRAY]:
    'Event location contact emails',

  [AutomationDataAccessType.DEAL_SITEADDRESSES_ARRAYSTRING]:
    'Project site address(es)',

  [AutomationDataAccessType.INVOICE_READABLE_STATUS]: 'Invoice status',
  [AutomationDataAccessType.INVOICE_REFERENCE]: 'Invoice ref',
  [AutomationDataAccessType.INVOICE_AMOUNTDUE]: 'Invoice amount due',
  [AutomationDataAccessType.INVOICE_DUEAT_FORMATTEDDATE]: 'Invoice due at date',
  [AutomationDataAccessType.INVOICE_VIEWLINK]: 'Invoice view link',
  [AutomationDataAccessType.INVOICE_VIEWBUTTON]: 'Invoice view button',
  [AutomationDataAccessType.INVOICE_SENTAT_FORMATTEDDATE]:
    'Invoice sent at date',
  [AutomationDataAccessType.INVOICE_VIEWEDAT_FORMATTEDDATE]:
    'Invoice viewed at date',
  [AutomationDataAccessType.INVOICE_CREATEDAT_FORMATTEDDATE]:
    'Invoice created at date',

  [AutomationDataAccessType.PROPOSAL_READABLE_STATUS]: 'Proposal status',
  [AutomationDataAccessType.PROPOSAL_REFERENCE]: 'Proposal ref',
  [AutomationDataAccessType.PROPOSAL_VALIDUNTIL_FORMATTEDDATE]:
    'Proposal valid until date',
  [AutomationDataAccessType.PROPOSAL_VIEWLINK]: 'Proposal view link',
  [AutomationDataAccessType.PROPOSAL_VIEWBUTTON]: 'Proposal view button',
  [AutomationDataAccessType.PROPOSAL_SENTAT_FORMATTEDDATE]:
    'Proposal sent at date',
  [AutomationDataAccessType.PROPOSAL_VIEWEDAT_FORMATTEDDATE]:
    'Proposal viewed at date',
  [AutomationDataAccessType.PROPOSAL_CREATEDAT_FORMATTEDDATE]:
    'Proposal created at date',
  [AutomationDataAccessType.PROPOSAL_ACCEPTEDAT_FORMATTEDDATE]:
    'Proposal accepted at date',
  [AutomationDataAccessType.PROPOSAL_ACCEPTEDAT_FORMATTEDTIME]:
    'Proposal accepted at time',
};

export const automationEntityTypeReadableNameMap: {
  [key in AutomationEntityTypes]?: string;
} = {
  [AutomationEntityTypes.INVOICE_V2]: 'Invoice',
  [AutomationEntityTypes.DEAL]: 'Project',
};

export const automationActionTypeReadableNameMap: {
  [key in AutomationActionTypes]?: string;
} = {
  [AutomationActionTypes.DEAL_PROGRESSION]: 'Project progression',
};
