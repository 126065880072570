import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';

import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import {
  PublicServicePlanPrice,
  PublicServicePlanSubscription,
} from '@payaca/types/service-plans';

import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';

import { useAccount } from '../../../utils/storeHooks';
import StatusBadge from '@payaca/components/statusBadge/StatusBadge';

type Props = {
  subscribers: PublicServicePlanSubscription[];
  servicePlanPrices: PublicServicePlanPrice[];
};
const ServicePlanSubscribersList = ({
  servicePlanPrices,
  subscribers,
}: Props) => {
  const history = useHistory();
  const account = useAccount();

  const subscribersWithBilling = useMemo(() => {
    return subscribers.map((sub) => {
      const servicePlanPrice = servicePlanPrices.find(
        (spp) => spp.publicId === sub.servicePlanPricePublicId
      );
      const displayBillingInterval =
        servicePlanPrice?.billingInterval === 'year'
          ? 'Annual'
          : servicePlanPrice?.billingInterval === 'month'
          ? 'Month'
          : '';
      return {
        ...sub,
        billingInterval: displayBillingInterval,
      };
    });
  }, [subscribers]);

  const timestampRegionalFormat = useMemo(
    () => getInternationalDateFormatByRegion(DateFormats.MID, account.region),
    [account.region]
  );

  return (
    <div className="service-plan-subscribers-list">
      {subscribersWithBilling.map((subscriber, i) => (
        <div
          key={`service-plan-customer-${i}`}
          className="service-plan-customer"
        >
          <div className="service-plan-customer-body">
            <div>
              <Button
                onClick={() =>
                  history.push(
                    `/customers/${subscriber.customerId}?servicePlanId=${subscriber.servicePlanPublicId}`
                  )
                }
                styleVariant={ButtonStyleVariant.ANCHOR}
              >
                {subscriber.customerName}
              </Button>
            </div>
            <span>
              {subscriber.status === 'active' ? (
                <>
                  Joined{' '}
                  {moment(subscriber.validFrom).format(timestampRegionalFormat)}
                </>
              ) : (
                <>Payment pending</>
              )}
            </span>
            <StatusBadge>{subscriber.billingInterval}</StatusBadge>
          </div>
          <QuickActionsElement
            recordId={subscriber.customerId}
            quickActions={[
              {
                actionName: 'View Service Periods',
                actionBehaviour: () => {
                  history.push(
                    `/service-plans/service-periods?customerId=${subscriber.customerId}`
                  );
                },
              },
            ]}
          />
        </div>
      ))}
    </div>
  );
};

export default ServicePlanSubscribersList;
