import { useQuery } from '@tanstack/react-query';

import { apiFetch } from '@/utils/apiFetch';
import connectionApplicationKeys from '@/api/queries/connectionApplication/keyFactory';
import { LowCarbonTech } from '@payaca/types/deviceSearchTypes';

/**
 * Represents an intermix of a JSONSchema with additional inline annotations that inform how to render a form for that schema
 */
export type FormAndJSONSchema = Record<string, any> & {
  properties: Record<string, FormSection>;
  definitions: Record<string, any>;
  fixedInputs: Record<string, any>;
};

export type FormSection = Record<string, any> & {
  title: string;
  order: number;
};

const useGetConnectionApplicationSchema = (lcts?: LowCarbonTech[]) => {
  return useQuery({
    enabled: !!lcts && lcts.length > 0,
    queryKey: connectionApplicationKeys.schema(lcts),
    queryFn: async () => {
      const q = new URLSearchParams();
      for (const lct of lcts!) {
        q.append('lcts', lct);
      }

      const response = await apiFetch(
        `/provider/rest/ena/connection-application/schema?${q}`,
        {
          method: 'GET',
        }
      );

      return response.json() as Promise<FormAndJSONSchema>;
    },
  });
};

export default useGetConnectionApplicationSchema;
