import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';

import { currencyPrice } from '@/helpers/financeHelper';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import { formatPhoneNumber } from '@payaca/helpers/phoneNumberHelper';
import { getAddressAsString } from '@payaca/helpers/locationHelper';

import { ListedDeal } from '@payaca/types/listedDealTypes';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { isNullish } from '@payaca/utilities/guards';
import { PipelineStageBadge } from '@payaca/components/pipelineStageBadge/PipelineStageBadge';
import { PipelineBadge } from '../pipelineBadge/PipelineBadge';
import ListedDealInfoIcons from '../listedDealInfoIcons/ListedDealInfoIcons';
import AssignedUsersIndicator from '@payaca/components/assignedUsersIndicator/AssignedUsersIndicator';
import { useAccount, useAccountUsers } from '../../../utils/storeHooks';
import { Link } from 'react-router-dom';

type Props = {
  collapse?: boolean;
  listedDeal: ListedDeal;
  listedDealIndex: number;
  onClickRow: (DealId: number) => void;
  hideCustomerColumn?: boolean;
};
const ListedDealsTableRow: FC<Props> = ({
  listedDeal,
  listedDealIndex,
  onClickRow,
  collapse,
  hideCustomerColumn,
}: Props): JSX.Element => {
  const account = useAccount();
  const accountUsers = useAccountUsers();

  const renderCustomerTableCell = useCallback(
    (rowSpan: number) => {
      return (
        <td className="customer-table-cell" rowSpan={rowSpan}>
          <Link
            to={`/deals/${listedDeal.dealId}`}
            className="text-inherit hover:no-underline"
          >
            <strong className="customer-contact-name">
              {listedDeal.customerName}
              {listedDeal.contactName &&
              listedDeal.contactName !== listedDeal.customerName
                ? `: ${listedDeal.contactName}`
                : ''}
            </strong>
          </Link>
          {listedDeal.contactEmail && <small>{listedDeal.contactEmail}</small>}
          {listedDeal.contactPhoneNumber && (
            <small>{formatPhoneNumber(listedDeal.contactPhoneNumber)}</small>
          )}
        </td>
      );
    },
    [listedDeal]
  );

  const renderReferenceTableCell = useCallback(() => {
    return (
      <td className="reference-table-cell">
        {listedDeal.reference}
        {!hideCustomerColumn && (
          <strong className="customer-contact-name">
            {listedDeal.customerName}
          </strong>
        )}
      </td>
    );
  }, [hideCustomerColumn, listedDeal]);

  const renderAddressesTableCell = useCallback(
    (rowSpan: number) => {
      const hasAnySiteAddresses = !!listedDeal.siteAddresses?.length;
      const billingAddressAsString =
        listedDeal.customerBillingAddress &&
        getAddressAsString(listedDeal.customerBillingAddress);
      const hasBillingAddress =
        billingAddressAsString && billingAddressAsString.length > 0;

      return (
        <td rowSpan={rowSpan} className="addresses-table-cell">
          {!hasAnySiteAddresses && hasBillingAddress && (
            <small>{billingAddressAsString}</small>
          )}
          {hasAnySiteAddresses && hasBillingAddress && (
            <small>
              <LabelValuePair label="Billing" value={billingAddressAsString} />
            </small>
          )}
          {hasAnySiteAddresses && (
            <small>
              <LabelValuePair
                label="Site"
                value={`${getAddressAsString(listedDeal.siteAddresses[0])}${
                  listedDeal.siteAddresses.length > 1
                    ? ` (+ ${listedDeal.siteAddresses.length - 1} more)`
                    : ''
                }`}
              />
            </small>
          )}
        </td>
      );
    },
    [listedDeal]
  );

  const renderCollapsedTableRow = useMemo(() => {
    return (
      <>
        <tbody
          className={'listed-deal-table-row-container clickable'}
          onClick={() => onClickRow(listedDeal.dealId)}
          key={`${listedDeal.dealId}-${listedDealIndex}-body`}
        >
          <tr>
            {renderReferenceTableCell()}
            <td className="align-right">
              {isNullish(listedDeal.dealValue)
                ? '-'
                : currencyPrice(listedDeal.dealValue, account.region)}
            </td>
            <td className="align-left">
              <PipelineBadge pipelineId={listedDeal.pipelineId} />
            </td>
            <td className="align-left">
              <PipelineStageBadge pipelineStage={listedDeal.pipelineStage} />
            </td>
          </tr>
        </tbody>
      </>
    );
  }, [listedDeal, listedDealIndex, renderReferenceTableCell, account]);

  const shortDateRegionalFormat = useMemo(
    () => getInternationalDateFormatByRegion(DateFormats.SHORT, account.region),
    [account]
  );

  const assignedUser = useMemo(() => {
    if (listedDeal.assignedToUserId) {
      return accountUsers.find(
        (user) => user.id === listedDeal.assignedToUserId
      );
    }
  }, [accountUsers]);

  const renderTableRow = useMemo(() => {
    return (
      <>
        <tbody
          className={'listed-deal-table-row-container clickable'}
          onClick={() => onClickRow(listedDeal.dealId)}
        >
          <tr>
            <td className="align-center">
              <strong>{listedDeal.reference}</strong>
            </td>
            {!hideCustomerColumn && renderCustomerTableCell(0)}
            {renderAddressesTableCell(0)}
            <td className="align-center description-table-cell" rowSpan={0}>
              <small>{listedDeal.description}</small>
            </td>
            <td className="align-center">
              {moment(listedDeal.lastUpdatedAt).format(shortDateRegionalFormat)}
            </td>
            <td className="align-right">
              {isNullish(listedDeal.dealValue)
                ? '-'
                : currencyPrice(listedDeal.dealValue, account.region)}
            </td>
            <td className="align-left">
              <PipelineBadge pipelineId={listedDeal.pipelineId} />
            </td>
            <td className="align-left">
              <PipelineStageBadge pipelineStage={listedDeal.pipelineStage} />
            </td>
            <td className="align-center">
              {assignedUser && (
                <AssignedUsersIndicator
                  assignedUsers={[
                    {
                      firstName: assignedUser.firstname,
                      lastName: assignedUser.lastname,
                      emailAddress: assignedUser.email,
                      userColour: assignedUser.userColour,
                    },
                  ]}
                />
              )}
            </td>
            <td className="align-right">
              <ListedDealInfoIcons listedDeal={listedDeal} />
            </td>
          </tr>
        </tbody>
      </>
    );
  }, [
    listedDeal,
    listedDealIndex,
    renderAddressesTableCell,
    renderCustomerTableCell,
    account,
    shortDateRegionalFormat,
    hideCustomerColumn,
  ]);

  return collapse ? renderCollapsedTableRow : renderTableRow;
};

export default ListedDealsTableRow;
