import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import EntityCard from '@payaca/components/entityCard/EntityCard';
import Modal from '@payaca/components/modal/Modal';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import ServicePlanSubscribersList from './ServicePlanSubscribersList';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';

import {
  ListedServicePlan,
  PublicHydratedServicePlanDiscountCode,
} from '@payaca/types/service-plans';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';

import { useAccount } from '../../../utils/storeHooks';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  getServicePlanMonthlyPrice,
  getServicePlanYearlyPrice,
} from '@payaca/helpers/servicePlanHelper';
import { getAccountRegionFromCurrencyCode } from '@payaca/helpers/internationalHelper';

import './ListedServicePlan.sass';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ServicePlanPermissions } from '@payaca/permissions/service-plans/service-plans.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getUserRoles } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';
import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';
import CopyServicePlanPublicLinkModal, {
  useCopyServicePlanPublicLinkToClipboard,
} from '../copyServicePlanPublicLinkModal/CopyServicePlanPublicLinkModal';

type Props = {
  listedServicePlan: ListedServicePlan;
  openInviteCustomerModal: () => void;
};
const ListedServicePlan = ({
  listedServicePlan,
  openInviteCustomerModal,
}: Props) => {
  const dispatch = useDispatch();
  const account = useAccount();
  const history = useHistory();
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const [showSubscribers, setShowSubscribers] = useState(false);
  const [showDeleteServicePlanModal, setShowDeleteServicePlanModal] =
    useState(false);
  const [
    showCopyServicePlanPublicLinkModal,
    setShowCopyServicePlanPublicLinkModal,
  ] = useState(false);
  const [servicePlanDiscountCodes, setSericePlanDiscountCodes] = useState<
    PublicHydratedServicePlanDiscountCode[]
  >([]);
  const [isDeletingServicePlan, setIsDeletingServicePlan] = useState(false);

  useEffect(() => {
    dispatch(
      servicePlansActions.getServicePlanDiscountCodes.request({
        publicId: listedServicePlan.publicId,
        callback: (res) => setSericePlanDiscountCodes(res),
      })
    );
  }, [listedServicePlan]);

  const monthlyPrice = useMemo(() => {
    return getServicePlanMonthlyPrice(listedServicePlan.prices);
  }, [listedServicePlan.prices]);

  const yearlyPrice = useMemo(() => {
    return getServicePlanYearlyPrice(listedServicePlan.prices);
  }, [listedServicePlan.prices]);

  const planCanBeSold = useMemo(
    () => listedServicePlan.planCanBeSold,
    [listedServicePlan]
  );

  const userRoles = useSelector(getUserRoles);

  const copyServicePlanPublicLinkToClipboard =
    useCopyServicePlanPublicLinkToClipboard();

  const quickActions = useMemo(() => {
    const qa = [
      {
        actionName: 'View Service Periods',
        actionBehaviour: () =>
          history.push(
            `/service-plans/service-periods?servicePlanPublicIds=${listedServicePlan.publicId}`
          ),
      },
    ];
    if (planCanBeSold) {
      qa.push({
        actionName: 'Copy public link',
        actionBehaviour: async () => {
          if (servicePlanDiscountCodes.length > 0) {
            setShowCopyServicePlanPublicLinkModal(true);
          } else {
            copyServicePlanPublicLinkToClipboard(listedServicePlan.publicId);
          }
        },
      });
    }
    if (
      userHasRequiredPermission(userRoles, [
        ServicePlanPermissions.PERSIST_SERVICE_PLAN,
      ])
    ) {
      qa.push({
        actionName: 'Edit',
        actionBehaviour: () =>
          history.push(
            `/service-plans/service-plan/${listedServicePlan.publicId}`
          ),
      });

      const hasAnySubscribers = listedServicePlan.subscriptions.some(
        (x) => x.servicePlanPublicId === listedServicePlan.publicId
      );
      if (!hasAnySubscribers) {
        qa.push({
          actionName: 'Delete',
          actionBehaviour: () => setShowDeleteServicePlanModal(true),
        });
      }
    }
    return qa;
  }, [
    listedServicePlan.publicId,
    planCanBeSold,
    userRoles,
    servicePlanDiscountCodes,
    copyServicePlanPublicLinkToClipboard,
  ]);

  const getRoundedMrrPrice = (mrr: number) => {
    if (mrr < 10000) {
      return currencyPrice(Math.round(mrr / 100) * 100, account.region, false); // less than 100, round to nearest £1
    } else if (mrr < 100000) {
      return currencyPrice(
        Math.round(mrr / 1000) * 1000,
        account.region,
        false
      ); // less than 1000, round to neared £10
    } else {
      return `${currencyPrice(
        Math.round(mrr / 10000) * 10,
        account.region,
        false
      )}k`; // round to nearest £100
    }
  };

  const monthlyTaxLabel = useMemo(() => {
    return monthlyPrice?.currencyCode
      ? ` (${monthlyPrice?.priceIncludesTax ? 'inc' : 'ex'} tax)`
      : '';
  }, [monthlyPrice?.currencyCode, monthlyPrice?.priceIncludesTax]);
  const yearlyTaxLabel = useMemo(() => {
    return yearlyPrice?.currencyCode
      ? ` (${yearlyPrice?.priceIncludesTax ? 'inc' : 'ex'} tax)`
      : '';
  }, [yearlyPrice?.currencyCode, yearlyPrice?.priceIncludesTax]);

  const numPendingSubscriptions = listedServicePlan.subscriptions.filter(
    (x) => x.status === 'pending'
  ).length;

  const handleDeleteServicePlan = () => {
    setIsDeletingServicePlan(true);

    dispatch(
      servicePlansActions.deleteServicePlan.request({
        servicePlanPublicId: listedServicePlan.publicId,
        callback: () => {
          dispatch(servicePlansActions.getListedServicePlans.request({}));
          setIsDeletingServicePlan(false);
          setShowDeleteServicePlanModal(false);
        },
        onErrorCallback: () => {
          setIsDeletingServicePlan(false);
          showDynamicFeedbackMessage({
            lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
            body: 'Something went wrong deleting your Service Plan. Please try again later or contact support.',
            feedbackLevel: FeedbackLevel.ERROR,
            isCancellable: true,
          });
        },
      })
    );
  };

  return (
    <>
      <EntityCard
        className="listed-service-plan"
        onClick={() =>
          !!listedServicePlan.subscriptions.length &&
          setShowSubscribers(!showSubscribers)
        }
        quickActionsConfig={{
          recordId: listedServicePlan.publicId,
          renderDisabledIfNoActions: true,
          quickActions,
        }}
      >
        <ResponsiveViewWrapper
          className="listed-service-plan-card-content"
          downBreakpointSm={600}
          downBreakpointXs={400}
        >
          <h3>{listedServicePlan.name}</h3>
          <div className="data-wrapper">
            <LabelValuePair
              label="Subscribers"
              value={`${listedServicePlan.stats.numActiveSubscriptions}${
                numPendingSubscriptions
                  ? ` (${numPendingSubscriptions} pending)`
                  : ''
              }`}
              suffixLabelWith=""
            />
          </div>
          <div className="data-wrapper">
            <Tooltip
              text={`Monthly recurring revenue${
                monthlyTaxLabel || yearlyTaxLabel
              }`}
            >
              <LabelValuePair
                label="MRR"
                value={
                  listedServicePlan.stats.monthlyRecurringRevenue
                    ? getRoundedMrrPrice(
                        listedServicePlan.stats.monthlyRecurringRevenue
                      )
                    : '-'
                }
                suffixLabelWith=""
              />
            </Tooltip>
          </div>
          {/* prices */}
          <div className="data-wrapper">
            <Tooltip text={`Monthly price${monthlyTaxLabel}`}>
              <LabelValuePair
                label="Price (Month)"
                value={
                  monthlyPrice?.basicPrice || monthlyPrice?.basicPrice === 0
                    ? currencyPrice(
                        monthlyPrice.basicPrice,
                        monthlyPrice.currencyCode
                          ? getAccountRegionFromCurrencyCode(
                              monthlyPrice.currencyCode
                            )
                          : undefined
                      )
                    : '-'
                }
                suffixLabelWith=""
              />
            </Tooltip>
          </div>
          <div className="data-wrapper">
            <Tooltip text={`Annual price${yearlyTaxLabel}`}>
              <LabelValuePair
                label="Price (Year)"
                value={
                  yearlyPrice?.basicPrice
                    ? currencyPrice(
                        yearlyPrice.basicPrice,
                        yearlyPrice.currencyCode
                          ? getAccountRegionFromCurrencyCode(
                              yearlyPrice.currencyCode
                            )
                          : undefined
                      )
                    : '-'
                }
                suffixLabelWith=""
              />
            </Tooltip>
          </div>
          {/* create send invite to customer */}
          <div className="invite-button-wrapper">
            {planCanBeSold && (
              <div onClick={(e) => e.stopPropagation()}>
                <Button
                  onClick={openInviteCustomerModal}
                  styleVariant={ButtonStyleVariant.ANCHOR}
                >
                  Invite Customer
                </Button>
              </div>
            )}
            {!planCanBeSold && listedServicePlan.planCanBeSoldResultReason && (
              <Tooltip text={listedServicePlan.planCanBeSoldResultReason}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            )}
          </div>
        </ResponsiveViewWrapper>
        {showSubscribers && (
          <ServicePlanSubscribersList
            subscribers={listedServicePlan.subscriptions}
            servicePlanPrices={listedServicePlan.prices}
          />
        )}
      </EntityCard>

      <Modal
        isOpen={showDeleteServicePlanModal}
        onClose={() => setShowDeleteServicePlanModal(false)}
        title={'Notice'}
        actions={
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            colourVariant={ButtonColourVariant.RED}
            isProcessing={isDeletingServicePlan}
            isDisabled={isDeletingServicePlan}
            onClick={handleDeleteServicePlan}
          >
            Delete
          </Button>
        }
      >
        <p>Are you sure you want to delete this service plan?</p>
        <p>
          <strong>
            This action will not be reflected in any third-party integrations.
          </strong>
        </p>
      </Modal>

      <CopyServicePlanPublicLinkModal
        servicePlanPublicId={listedServicePlan.publicId}
        servicePlanDiscountCodes={servicePlanDiscountCodes}
        isOpen={showCopyServicePlanPublicLinkModal}
        onClose={() => setShowCopyServicePlanPublicLinkModal(false)}
      />
    </>
  );
};

export default ListedServicePlan;
