import React, { FC } from 'react';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import { sentenceCase } from '@payaca/utilities/stringUtilities';

export interface IProps {
  selectedDevice: any;
}

const SearchedDeviceDetailsCard: FC<IProps> = (props) => {
  const { selectedDevice } = props;

  const labels = [
    {
      label: 'Device reference',
      value: selectedDevice.deviceRef,
    },
    {
      label: 'Device model number',
      value: selectedDevice.deviceModelNo,
    },
    {
      label: 'Number of phases',
      value: selectedDevice.devicePhases,
    },
    {
      label: 'Manufacturer',
      value: selectedDevice.deviceManufacturer,
    },
    {
      label: sentenceCase(selectedDevice.deviceSizeName),
      value: `${selectedDevice.deviceSize} ${selectedDevice.deviceSizeUnit}`,
    },
  ];

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <h4>Selected device details</h4>

        {labels.map(({ label, value }) => (
          <LabelValuePair key={value} label={label} value={value} />
        ))}
      </Card.Body>
    </Card>
  );
};

export default SearchedDeviceDetailsCard;
