import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams, useRouteMatch } from 'react-router-dom';
import { format, formatDistance } from 'date-fns';

import ScheduleContextProvider, {
  ScheduleContext,
} from '@/ui/components/contextProviders/ScheduleContextProvider';
import ScheduleDispatchFilters from '@/ui/components/scheduleDispatchFilters/ScheduleDispatchFilters';
import { ScheduleDispatchMain } from '@/ui/components/scheduleDispatchMain/ScheduleDispatchMain';
import useGetCustomerEvents from '@/api/queries/customers/useGetCustomerEvents';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import DateTab from '@payaca/components/dateTab/DateTab';
import CardLink from '@payaca/components/plCard/CardLink';
import { DeepPartial } from '@payaca/utilities/types';
import { CreateScheduledEventRequestData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';
import useGetCustomer from '@/api/queries/customers/useGetCustomer';
import useGetMe from '@/api/queries/me/useGetMe';
import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import keyFactory from '@/api/queries/customers/keyFactory';

const CustomerScheduleTab: FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: meData } = useGetMe();
  const { data: customerData } = useGetCustomer(customerId);

  const initialScheduledEvent: DeepPartial<CreateScheduledEventRequestData> =
    useMemo(() => {
      const initialScheduledEvent: DeepPartial<CreateScheduledEventRequestData> =
        {
          customerIds: [parseInt(customerId)],
          includeCustomerInformation: true,
          name: customerData?.customer.name,
          userAssignments: meData?.me.user.id ? [+meData.me.user.id] : [],
        };

      initialScheduledEvent.contactsToNotify = customerData?.customer
        .primaryContact
        ? [
            {
              type: 'customerContact' as const,
              id: +customerData.customer.primaryContact.id,
              name: customerData.customer.primaryContact.name || undefined,
              emailAddress:
                customerData.customer.primaryContact.email || undefined,
              telephoneNumber:
                customerData.customer.primaryContact.phone || undefined,
              description:
                customerData.customer.primaryContact.description || undefined,
            },
          ]
        : [];

      return initialScheduledEvent;
    }, [customerId, meData, customerData]);

  return (
    <ScheduleContextProvider
      createEventBase={initialScheduledEvent}
      disabledFields={['customerIds']}
      hiddenFields={['dealId', 'customerIds']}
    >
      <CustomerScheduleTabContact />
    </ScheduleContextProvider>
  );
};

const CustomerScheduleTabContact: FC = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const {
    data: customerEventsData,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetCustomerEvents(customerId, {
    sortDirection: 'desc',
  });
  const { url } = useRouteMatch();
  const queryClient = useQueryClient();
  const { listenerSubscribe, listenerUnsubscribe } =
    useContext(ScheduleContext);

  useEffect(() => {
    const invalidateCustomerEventQuery = () => {
      queryClient.invalidateQueries(keyFactory.customerEvents(customerId));
    };

    listenerSubscribe(
      'onEventCreated',
      'scheduleTabContent',
      invalidateCustomerEventQuery
    );

    listenerSubscribe(
      'onEventUpdated',
      'scheduleTabContent',
      invalidateCustomerEventQuery
    );

    listenerSubscribe(
      'onEventDeleted',
      'scheduleTabContent',
      invalidateCustomerEventQuery
    );

    return () => {
      listenerUnsubscribe('onEventCreated', 'scheduleTabContent');
      listenerUnsubscribe('onEventUpdated', 'scheduleTabContent');
      listenerUnsubscribe('onEventDeleted', 'scheduleTabContent');
    };
  }, [queryClient, customerId]);

  return (
    <div className="@container/scheduletab">
      <div className="@screen-lg/scheduletab:flex-row flex flex-col gap-8">
        <div className="@screen-lg/scheduletab:basis-80 shrink-0 grow-0 space-y-4">
          {customerEventsData?.pages.map((customerData) =>
            customerData.customer.events.items.map((event) => (
              <CardLink
                key={event.id}
                to={`${url}/${event.id}`}
                sizeVariant={CardSizeVariant.SM}
              >
                <Card.Body>
                  <div className="flex items-center gap-4">
                    <DateTab date={new Date(event.beginAt)} />

                    <div>
                      <p className="supporting-body">
                        {format(event.beginAt, 'EEEE do MMM yyyy')}
                      </p>
                      <h4>{event.name}</h4>
                      <p>
                        {format(event.beginAt, 'HH:mm')} -{' '}
                        {format(event.endAt, 'HH:mm')} (
                        {formatDistance(event.beginAt, event.endAt)})
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </CardLink>
            ))
          )}

          <Conditional
            condition={
              customerEventsData?.pages[0] &&
              customerEventsData.pages[0].customer.events.totalCount === 0 &&
              !isLoading
            }
          >
            <>
              <h3>No Events</h3>
              <p>This Customer {"doesn't"} have any Events yet</p>
            </>
          </Conditional>

          <Conditional condition={hasNextPage}>
            <div className="text-center">
              <Button
                size={EBtnSize.Small}
                variant={EBtnVariant.White}
                onClick={() => fetchNextPage()}
                isProcessing={isFetchingNextPage}
              >
                Load more
              </Button>
            </div>
          </Conditional>
        </div>

        <div className="flex grow flex-col gap-4">
          <ScheduleDispatchFilters />
          <ScheduleDispatchMain />
        </div>
      </div>
    </div>
  );
};

export default CustomerScheduleTab;
