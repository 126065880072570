import React, { useCallback, useMemo } from 'react';
import { useNote } from '@payaca/store/hooks/appState';
import moment from 'moment-timezone';

import { useSelector } from '../../../api/state';
import { useAccount, useAccountUser } from '../../../utils/storeHooks';
import { isValidHtml, parseHtmlToReact } from '@payaca/utilities/htmlUtilities';
import { getNoteTitle } from '@payaca/helpers/noteHelper';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';

import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';
import QuickActionsElement, { QuickActionConfig } from '@payaca/components/quickActionsTableCell/QuickActionsElement';

import { Note } from '@payaca/types/notesTypes';

import './NoteControl.sass';

type Props = {
  noteId: number;
  onPersistNoteSuccess: () => void;
  editNote: (note: Note) => void;
  archiveNote: (noteId: number) => void;
};
const NoteControl = ({ archiveNote, editNote, noteId }: Props) => {
  const note = useNote(noteId);
  const account = useAccount();
  const profile = useSelector((state: any) => state.users.myProfile);
  const createdByUser = useAccountUser(note?.createdByUserId);

  const timestampShortDateRegionalFormat = useMemo(
    () =>
      getInternationalDateFormatByRegion(
        DateFormats.TIMESTAMP_SHORT,
        account.region
      ),
    [account]
  );

  const canEditNote = useMemo(
    () => note && note.createdByUserId === profile.id,
    [note, profile.id]
  );

  const quickActions = useMemo(() => {
    const quickActions: QuickActionConfig[] = [];
    if (canEditNote) {
      quickActions.push({
        actionName: 'Edit',
        actionBehaviour: () => {
          note && editNote(note);
        },
      });
    }
    quickActions.push({
      actionName: 'Delete',
      actionBehaviour: () => archiveNote(noteId),
    });
    return quickActions;
  }, [editNote, note, noteId]);

  const noteTitle = useMemo(
    () => getNoteTitle(note, createdByUser),
    [note, createdByUser]
  );

  if (!note) {
    return null;
  }
  return (
    <div className="note-control flex-container flex-center">
      <div
        className={`note-control-panel flex-center flex-grow${
          canEditNote ? ' clickable' : ''
        }`}
        onClick={canEditNote ? () => editNote(note) : undefined}
      >
        <>
          <div className="note-header">
            <div>
              <strong>{noteTitle}</strong>
              {createdByUser &&
                `by ${createdByUser?.firstname} ${createdByUser?.lastname}`}
            </div>
            <small className="timestamp">
              {moment(note.createdAt).format(timestampShortDateRegionalFormat)}
            </small>
          </div>
          {isValidHtml(note.description) ? (
            parseHtmlToReact(note.description)
          ) : (
            <MarkdownLabel markdown={note.description} />
          )}
        </>
      </div>
      <QuickActionsElement
        recordId={noteId}
        quickActions={quickActions}
        renderDisabledIfNoActions={true}
      />
    </div>
  );
};

export default NoteControl;
