import { getRegion } from '@/utils/stateAccessors';
import {
  faCheckCircle,
  faClock,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
  getRegionalTextString,
} from '@payaca/helpers/internationalHelper';
import {
  useMaterialPurchaseIntent,
  useSupplier,
  usePurchaseOrder,
} from '@payaca/store/hooks/appState';
import moment from 'moment-timezone';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

import './MaterialPurchaseIntent.sass';
import { requestDeleteMaterialPurchaseRecord } from '@payaca/store/materialsList/materialsListActions';
import { RegionalStrings } from '@payaca/types/internationalTypes';

interface Props {
  materialPurchaseIntentId: number;
}

const MaterialPurchaseIntent: FC<Props> = ({
  materialPurchaseIntentId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const materialPurchaseIntent = useMaterialPurchaseIntent(
    materialPurchaseIntentId
  );
  const purchaseOrder = usePurchaseOrder(
    materialPurchaseIntent?.purchaseOrderId
  );

  const supplier = useSupplier(
    purchaseOrder?.supplierId || materialPurchaseIntent?.supplierId
  );

  if (!materialPurchaseIntent) return null;

  return (
    <div className="material-purchase-intent">
      <FontAwesomeIcon icon={faClock} />
      <span>
        <strong>{materialPurchaseIntent?.materialQuantity}</strong>
        {purchaseOrder ? (
          <>
            {' '}
            included in purchase order{' '}
            <strong>
              {purchaseOrder.reference}
              {supplier && <>: {supplier.name}</>}
            </strong>
          </>
        ) : (
          <>
            {' '}
            intended to be purchased
            {supplier && (
              <>
                {' '}
                from <strong>{supplier.name}</strong>
              </>
            )}
          </>
        )}
      </span>
    </div>
  );
};

export default MaterialPurchaseIntent;
