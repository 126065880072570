import Field, { ValidationMessages } from '@payaca/components/plField/Field';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, get, useFormContext, useWatch } from 'react-hook-form';
import { Props as DynamicField } from './DynamicField';
import Combobox from '@payaca/components/plCombobox/Combobox';
import useDeviceSearch from '../../../api/rest/connectionApplication/useDeviceSearch';
import {
  LowCarbonTech,
  SearchDevice,
  SupportingLowCarbonTech,
} from '@payaca/types/deviceSearchTypes';
import { sentenceCase } from '@payaca/utilities/stringUtilities';
import SearchedDeviceDetailsCard from '@/ui/pages/connectionApplicationPage/components/SearchedDeviceDetailsCard';
import SearchedDeviceDetailsCardSkeleton from './components/SearchedDeviceDetailsCardSkeleton';
import { usePrevious } from '@payaca/hooks/usePrevious';

type Props = Omit<
  DynamicField,
  'definitions' | 'namespace' | 'dependentFields'
> & {
  fieldProps: any;
  deviceType: LowCarbonTech | SupportingLowCarbonTech;
};

const DeviceSearchField: FC<Props> = ({
  fieldProps,
  name,
  fieldSchema,
  deviceType,
}) => {
  const form = useFormContext();
  const selectedDeviceRef = useWatch({ name });
  const error = get(form.formState.errors, name);

  const [query, setQuery] = useState<string>(selectedDeviceRef);
  const [phaseCode] = form.getValues(['supplyDetails.phaseCode']);
  const previousDeviceType = usePrevious(deviceType);

  const {
    data: deviceSearchResults,
    refetch: fetchSearchDevice,
    isRefetching,
  } = useDeviceSearch(name, deviceType, query, phaseCode);

  const selectedDevice = useMemo(() => {
    const fullDevice = (deviceSearchResults || [])?.find(
      (x: SearchDevice) => x.deviceRef === selectedDeviceRef
    );
    return fullDevice;
  }, [deviceSearchResults, selectedDeviceRef]);

  const options = useMemo(() => {
    return (
      (deviceSearchResults || [])?.map((x: SearchDevice) => ({
        value: x.deviceRef,
        label: x.displayValue,
      })) || []
    );
  }, [deviceSearchResults]);

  useEffect(() => {
    if (previousDeviceType && deviceType !== previousDeviceType) {
      // device type has been changed, reset the query
      setQuery('');
    }
  }, [deviceType, previousDeviceType]);

  useEffect(() => {
    // query changes, rerun the search
    fetchSearchDevice();
  }, [query]);

  return (
    <Field {...fieldProps}>
      <Field.Label>
        {sentenceCase(fieldSchema.title)}{' '}
        {fieldSchema.json_schema_extra.required ? '*' : ''}
      </Field.Label>
      <Controller
        control={form.control}
        name={name}
        render={({ field: { ref, value, ...rest } }) => (
          <Combobox
            {...rest}
            className="mb-3"
            value={value || null}
            options={options}
            query={query}
            setQuery={setQuery}
            customButtonContent={selectedDevice?.displayValue}
            placeholder="Start typing to search for a device..."
          />
        )}
      />
      {error && (
        <ValidationMessages
          validationState={{
            isValid: false,
            validationMessages: ['This field is required'],
          }}
        />
      )}
      {selectedDevice ? (
        <SearchedDeviceDetailsCard selectedDevice={selectedDevice} />
      ) : isRefetching && query ? (
        <SearchedDeviceDetailsCardSkeleton className="mt-4" />
      ) : null}
      <Field.Helper>{fieldSchema.json_schema_extra.tooltip}</Field.Helper>
    </Field>
  );
};

export default DeviceSearchField;
