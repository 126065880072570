export const DATE_TODAY_FORMAT = 'ddd DD MMM YYYY';
export const DATE_SHORT_FORMAT = "DD MMM 'YY";
export const DATE_MID_FORMAT = 'DD MMM YYYY';
export const DATE_SHORT_READABLE_FORMAT = 'Do MMMM YYYY';

export const DATE_TIMESTAMP_FORMAT = 'DD MMM YYYY HH:mm:ss z';
export const DATE_TIMESTAMP_SHORT_FORMAT = 'ddd D MMM HH:mm';
export const DATE_SHORT_DELIMITED_FORMAT = 'DD/MM/YYYY';

export const CA_DATE_TODAY_FORMAT = 'ddd MMM DD YYYY';
export const CA_DATE_SHORT_FORMAT = "MMM DD 'YY";
export const CA_DATE_MID_FORMAT = 'MMM DD YYYY';
export const CA_DATE_SHORT_READABLE_FORMAT = 'MMMM Do YYYY';
export const CA_DATE_TIMESTAMP_FORMAT = 'MMM DD YYYY HH:mm:ss z';
export const CA_DATE_TIMESTAMP_SHORT_FORMAT = 'ddd MMM D HH:mm';
export const CA_DATE_SHORT_DELIMITED_FORMAT = 'MM/DD/YYYY';

export const US_DATE_TODAY_FORMAT = 'ddd MMM DD YYYY';
export const US_DATE_SHORT_FORMAT = "MMM DD 'YY";
export const US_DATE_MID_FORMAT = 'MMM DD YYYY';
export const US_DATE_SHORT_READABLE_FORMAT = 'MMMM Do YYYY';
export const US_DATE_TIMESTAMP_FORMAT = 'MMM DD YYYY HH:mm:ss z';
export const US_DATE_TIMESTAMP_SHORT_FORMAT = 'ddd MMM D HH:mm';
export const US_DATE_SHORT_DELIMITED_FORMAT = 'MM/DD/YYYY';

// TODO: These are copy-pasta'd from the generic format strings.
// 1. Update them for correctness for South Africa;
// 2. Factor out all this stuff in favour of Intl.DateTimeFormat;
export const ZA_DATE_TODAY_FORMAT = 'ddd DD MMM YYYY';
export const ZA_DATE_SHORT_FORMAT = "DD MMM 'YY";
export const ZA_DATE_MID_FORMAT = 'DD MMM YYYY';
export const ZA_DATE_SHORT_READABLE_FORMAT = 'Do MMMM YYYY';
export const ZA_DATE_TIMESTAMP_FORMAT = 'DD MMM YYYY HH:mm:ss z';
export const ZA_DATE_TIMESTAMP_SHORT_FORMAT = 'ddd D MMM HH:mm';
export const ZA_DATE_SHORT_DELIMITED_FORMAT = 'DD/MM/YYYY';
