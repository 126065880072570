import React, { useState } from 'react';

import { BaseScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import { PublicHydratedServicePlanPeriodCommitment } from '@payaca/types/service-plans';

import ScheduledEventReadDrawer from '../scheduledEventReadDrawer/ScheduledEventReadDrawer';

import { useAccount } from '@/utils/storeHooks';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';
import { getTimeDuration } from '@payaca/utilities/timeUtilities';
import moment from 'moment-timezone';
import './ServicePlanPeriodEventCommitment.sass';
import ServicePlanPeriodCommitmentEntityCard from './ServicePlanPeriodCommitmentEntityCard';
import { useDispatch } from 'react-redux';
import { updateServicePlanPeriodCommitment } from '@payaca/store/servicePlans/servicePlansActions';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';

type Props = {
  commitment: PublicHydratedServicePlanPeriodCommitment;
  onCommitmentUpdate: () => void;
};
const ServicePlanPeriodEventCommitment = ({
  commitment,
  onCommitmentUpdate,
}: Props) => {
  const [showReadDrawer, setShowReadDrawer] = useState(false);
  const entity = commitment.entity as BaseScheduledEvent;
  const dispatch = useDispatch();

  const account = useAccount();

  const timestampShortDateRegionalFormat = getInternationalDateFormatByRegion(
    DateFormats.TIMESTAMP_SHORT,
    account.region
  );

  const duration = getTimeDuration(entity.beginAt, entity.endAt);

  return (
    <>
      <ServicePlanPeriodCommitmentEntityCard
        commitmentId={commitment.publicId}
        onClick={() => setShowReadDrawer(true)}
        entityType={commitment.entityType}
        status={commitment.status}
        onCommitmentUpdate={onCommitmentUpdate}
      >
        <ResponsiveViewWrapper
          className="service-plan-period-event-commitment-inner"
          downBreakpointSm={650}
          downBreakpointXs={350}
        >
          <h3 className="name">{entity.name}</h3>
          <dl className="start">
            <dt>Start</dt>
            <dd>
              {moment(entity.beginAt).format(timestampShortDateRegionalFormat)}
            </dd>
          </dl>
          {duration && (
            <dl className="duration">
              <dt>Duration</dt>
              <dd>
                {roundToDecimalPlaces(duration.value)} {duration.label}
              </dd>
            </dl>
          )}
        </ResponsiveViewWrapper>
      </ServicePlanPeriodCommitmentEntityCard>
      <ScheduledEventReadDrawer
        scheduledEventId={entity.id}
        isOpen={showReadDrawer}
        onClose={() => setShowReadDrawer(false)}
        onUpdateScheduledEventSuccess={onCommitmentUpdate}
        onDeleteScheduledEventSuccess={() => {
          dispatch(
            updateServicePlanPeriodCommitment.request({
              servicePlanPeriodCommitment: {
                publicId: commitment.publicId,
                entityId: null,
              },
              callback: () => onCommitmentUpdate(),
            })
          );
        }}
        onUpdateTasksSuccess={onCommitmentUpdate}
        hideNotes={true}
      />
    </>
  );
};

export default ServicePlanPeriodEventCommitment;
