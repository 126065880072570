import { useQuery } from '@tanstack/react-query';

import { apiFetch } from '@/utils/apiFetch';

const useDeviceSearch = (
  name: string,
  deviceType: string,
  searchTerm: string,
  phaseCode?: string | number
) => {
  return useQuery({
    queryKey: ['device-search', name],
    queryFn: async () => {
      const response = await apiFetch(
        '/provider/rest/ena/connection-application/device-search?' +
          new URLSearchParams({
            deviceType,
            phases: phaseCode ? `${phaseCode}` : '',
            searchTerm,
          }),
        {
          method: 'GET',
        }
      );

      return response.json();
    },
  });
};

export default useDeviceSearch;
