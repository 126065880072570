import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import JobStatusBadge from '../jobStatusBadge/JobStatusBadge';
import ResendJobModal from '../resendJobModal/ResendJobModal';
import RestoreJobModal from '../restoreJobModal/RestoreJobModal';
import { AlertTooltip } from '@payaca/components/iconTooltip/IconTooltip';

import { getRegion } from '@/utils/stateAccessors';
import {
  useProposal,
  useJobContent,
  useCustomerForDeal,
  useDeal,
} from '@payaca/store/hooks/appState';

import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { getAvailableActionsForJob } from '@payaca/helpers/jobActionsHelper';
import { getModal } from '@/helpers/modalHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getJobType } from '@payaca/helpers/jobHelperV2';

import { JobActionType } from '@payaca/types/jobActionsTypes';

import { actions as appActions } from '@/api/app';
import { actions as jobActions } from '@/api/jobs';
import { useSelector } from '@/api/state';

import './ProposalCard.sass';

interface Props {
  proposalId: number;
  onClick?: () => void;
  onDealUpdateSuccess?: () => void;
}

// NOTE: This card is only useable for proposals on a V2 deal. Using this for a V1 deal proposal will render incorrect quickactions
const ProposalCard: FC<PropsWithChildren<Props>> = ({
  proposalId,
  onClick,
  onDealUpdateSuccess,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const proposal = useProposal(proposalId);
  const jobContent = useJobContent(proposal?.jobContentId);
  const customer = useCustomerForDeal(proposal?.dealId);
  const deal = useDeal(proposal?.dealId);
  const [isDuplicatingJob, setIsDuplicatingJob] = useState(false);
  const [isArchivingJob, setIsArchivingJob] = useState(false);
  const [isMarkingJobAccepted, setIsMarkingJobAccepted] = useState(false);
  const [isMarkingJobDeclined, setIsMarkingJobDeclined] = useState(false);
  const [showResendJobModal, setShowResendJobModal] = useState(false);
  const [showRestoreJobModal, setShowRestoreJobModal] = useState(false);

  const region = useSelector(getRegion);
  const shortDateRegionalFormat = useMemo(
    () => getInternationalDateFormatByRegion(DateFormats.SHORT, region),
    [region]
  );

  const markJobAccepted = useCallback(
    (proposalId: number) => {
      setIsMarkingJobAccepted(true);
      dispatch(
        jobActions.acceptQuote(proposalId, () => {
          onDealUpdateSuccess?.();
          setIsMarkingJobAccepted(false);
        })
      );
    },
    [dispatch, onDealUpdateSuccess]
  );

  const markJobDeclined = useCallback(
    (proposalId: number) => {
      setIsMarkingJobDeclined(true);
      dispatch(
        jobActions.declineJob(proposalId, () => {
          onDealUpdateSuccess?.();
          setIsMarkingJobDeclined(false);
        })
      );
    },
    [dispatch, onDealUpdateSuccess]
  );

  const duplicateJob = useCallback(
    (proposalId: number) => {
      setIsDuplicatingJob(true);
      dispatch(
        jobActions.duplicateJob(proposalId, (error: any, response: any) => {
          if (!error) {
            history.push('/'); // this is needed so the page actually reloads - otherwise it sees that the route hasn't changed and doesn't do anything

            history.push(`/quotes/${response.newJobId}`);
          }
          setIsDuplicatingJob(false);
        })
      );
    },
    [dispatch, history]
  );

  const archiveJob = useCallback(
    (proposalId: number) => {
      dispatch(
        appActions.showModal(
          getModal('ARCHIVE_JOB', {
            primaryAction: () => {
              setIsArchivingJob(true);
              dispatch(
                jobActions.archiveJob(proposalId, () => {
                  dispatch(appActions.hideModal());
                  setIsArchivingJob(false);
                  onDealUpdateSuccess?.();
                })
              );
            },
            secondaryAction: () => dispatch(appActions.hideModal()),
            onClose: () => dispatch(appActions.hideModal()),
          })
        )
      );
    },
    [dispatch, history, onDealUpdateSuccess]
  );

  const goToSendJob = useCallback(
    (proposalId: number) => {
      history.push(`/quotes/${proposalId}?view=send`);
    },
    [history]
  );

  const proposalQuickActionDefinitions: { [key: string]: any } = useMemo(() => {
    return {
      [JobActionType.DUPLICATE]: {
        actionName: 'Duplicate',
        actionBehaviour: duplicateJob,
        isActionProcessing: isDuplicatingJob,
      },
      [JobActionType.MARK_AS_ACCEPTED]: {
        actionName: 'Mark as accepted',
        actionBehaviour: markJobAccepted,
        isActionProcessing: isMarkingJobAccepted,
      },
      [JobActionType.MARK_AS_DECLINED]: {
        actionName: 'Mark as declined',
        actionBehaviour: markJobDeclined,
        isActionProcessing: isMarkingJobDeclined,
      },
      [JobActionType.ARCHIVE]: {
        actionName: 'Archive',
        actionBehaviour: archiveJob,
        isActionProcessing: isArchivingJob,
      },
      [JobActionType.SEND]: {
        actionName: 'Send',
        actionBehaviour: goToSendJob,
      },
      [JobActionType.RESEND]: {
        actionName: 'Resend',
        actionBehaviour: () => setShowResendJobModal(true),
      },
      [JobActionType.UNARCHIVE]: {
        actionName: 'Restore',
        actionBehaviour: () => setShowRestoreJobModal(true),
      },
    };
  }, [
    duplicateJob,
    isDuplicatingJob,
    markJobAccepted,
    isMarkingJobAccepted,
    markJobDeclined,
    isMarkingJobDeclined,
    archiveJob,
    isArchivingJob,
    goToSendJob,
  ]);

  const availableActions = useMemo(
    () =>
      proposal && deal && customer
        ? getAvailableActionsForJob(proposal, deal, customer, [], [])
        : [],
    [proposal, deal, customer]
  );

  const quickActions = useMemo(() => {
    return availableActions
      .map((availableAction) => proposalQuickActionDefinitions[availableAction])
      .filter((x) => !!x);
  }, [availableActions, proposalQuickActionDefinitions]);

  if (!proposal || !jobContent) return null;

  const dateElement = () => {
    if (proposal.validUntil && !proposal.acceptedAt) {
      return (
        <>
          <dt>Valid until</dt>
          <dd>
            <time dateTime={proposal?.validUntil?.toString()}>
              {moment(proposal.validUntil).format(shortDateRegionalFormat)}
            </time>
          </dd>
        </>
      );
    } else if (proposal.acceptedAt) {
      return (
        <>
          <dt>Accepted at</dt>
          <dd>
            <time dateTime={proposal?.acceptedAt?.toString()}>
              {moment(proposal.acceptedAt).format(shortDateRegionalFormat)}
            </time>
          </dd>
        </>
      );
    }
  };

  const issue = (() => {
    if (proposal.bouncedAt) {
      return 'E-mail has bounced';
    }
  })();

  return (
    <>
      <EntityCard
        className="proposal-card"
        onClick={onClick}
        quickActionsConfig={{
          recordId: proposalId,
          renderDisabledIfNoActions: true,
          quickActions: quickActions,
        }}
      >
        <ResponsiveViewWrapper
          className="proposal-card-content"
          downBreakpointSm={500}
        >
          <>
            <JobStatusBadge status={proposal.readableStatus} />
            <dl className="price">
              <dt>
                {getJobType(proposal)}
                {` total (inc tax)`}
              </dt>
              <dd>{currencyPrice(jobContent.total, region)}</dd>
            </dl>
            <dl className="date">{dateElement()}</dl>
            {issue && (
              <div className={'card-issue'}>
                <AlertTooltip tooltipText={issue} />
              </div>
            )}
          </>
        </ResponsiveViewWrapper>
      </EntityCard>
      <ResendJobModal
        isOpen={showResendJobModal}
        onClose={() => {
          setShowResendJobModal(false);
          onDealUpdateSuccess?.();
        }}
        jobId={proposalId}
      />
      <RestoreJobModal
        isOpen={showRestoreJobModal}
        jobId={proposalId}
        onClose={() => setShowRestoreJobModal(false)}
        onRestoreJobCallback={() => {
          setShowRestoreJobModal(false);
          onDealUpdateSuccess?.();
        }}
      />
    </>
  );
};

export default ProposalCard;
