import { getRegion } from '@/utils/stateAccessors';
import { FC, useMemo } from 'react';
import './TaskDeadlineCompletionDateInformation.sass';

import DueMessage from '@payaca/components/dueMessage/DueMessage';
import { Danger, TimeCircle } from 'react-iconly';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import moment from 'moment-timezone';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { Task } from '@payaca/types/taskTypes';
import { useSelector } from '@/api/state';

const renderCustomDueMessage = (dueInDays: number, message: string) => {
  const className =
    dueInDays == 0 ? 'today' : dueInDays > 0 ? 'future' : 'past';

  let icon = undefined;

  if (dueInDays > 0) {
    icon = <TimeCircle set={'light'} size={20} />;
  } else {
    icon = <Danger set={'light'} size={20} />;
  }

  return (
    <div className={`task-due-message ${className}`}>
      {icon}
      <span>{message}</span>
    </div>
  );
};

type Props = {
  task: Task;
};
const TaskDeadlineCompletionDateInformation: FC<Props> = ({
  task,
}: Props): JSX.Element | null => {
  const region = useSelector(getRegion);

  const timestampRegionalFormat = useMemo(
    () => getInternationalDateFormatByRegion(DateFormats.SHORT, region),
    [region]
  );

  if (!task.deadlineDate && !task.completedAt) return null;
  return (
    <div className={'task-deadline-completion-date-information'}>
      {!!task.deadlineDate && !task.completedAt && (
        <div className="flex-container flex-center">
          <LabelValuePair
            label={'Deadline date'}
            value={moment(task.deadlineDate).format(timestampRegionalFormat)}
          />
          {!task.completedAt && (
            <DueMessage
              dueDate={task.deadlineDate}
              renderMessage={renderCustomDueMessage}
            />
          )}
        </div>
      )}
      {!!task.completedAt && (
        <div className="">
          <LabelValuePair
            label={'Completed'}
            value={moment(task.completedAt).format(timestampRegionalFormat)}
          />
        </div>
      )}
    </div>
  );
};
export default TaskDeadlineCompletionDateInformation;
