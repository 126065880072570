import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import FeedbackMessage from '@payaca/components/feedbackMessage/FeedbackMessage';
import StatusBadge, {
  StatusBadgeState,
} from '@payaca/components/statusBadge/StatusBadge';
import ConfirmPaymentModal from '../confirmPaymentModal/ConfirmPaymentModal';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import * as jobPaymentsActions from '@payaca/store/jobPayments/jobPaymentsActions';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';

import { getRegion, getUserRoles } from '@/utils/stateAccessors';

import { usePayment } from '@payaca/store/hooks/appState';

import { PaymentsPermissions } from '@payaca/permissions/payments/payments.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import { PaymentMethodType } from '@payaca/types/jobPaymentTypes';

import { useSelector } from '@/api/state';

import './PaymentCard.sass';
import { useDispatch } from 'react-redux';
import Badge from '@payaca/components/plBadge/Badge';
import { BadgeColourVariant } from '@payaca/types/plBadge';

interface Props {
  paymentId: number;
  onConfirmPaymentSuccess?: () => void;
}

const PaymentCard: FC<PropsWithChildren<Props>> = ({
  paymentId,
  onConfirmPaymentSuccess,
}) => {
  const dispatch = useDispatch();
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const [showConfirmFailedPaymentModal, setShowConfirmFailedPaymentModal] =
    useState(false);
  const payment = usePayment(paymentId);
  const userRoles = useSelector((state) => getUserRoles(state));
  const region = useSelector((state) => getRegion(state));
  const [isConfirmingFailed, setIsConfirmingFailed] = useState(false);

  const { paymentDate, paymentDateLabel, paymentStatus, badgeColour } =
    useMemo<{
      paymentStatus?: string;
      badgeColour?: BadgeColourVariant;
      paymentDate?: Date;
      paymentDateLabel?: string;
    }>(() => {
      if (!payment) {
        return {
          paymentStatus: undefined,
          badgeColour: undefined,
          paymentDate: undefined,
          paymentDateLabel: undefined,
        };
      }
      if (payment.paymentFailedConfirmationAt) {
        return {
          paymentStatus: 'Failed',
          badgeColour: 'red',
          paymentDate: payment.paymentFailedConfirmationAt,
          paymentDateLabel: 'Failed',
        };
      }
      if (payment.paymentCompletedConfirmationAt) {
        return {
          paymentStatus: 'Complete',
          badgeColour: 'teal',
          paymentDate: payment.paymentCompletedConfirmationAt,
          paymentDateLabel: 'Confirmed',
        };
      }
      return {
        paymentStatus: 'Pending',
        badgeColour: 'yellow',
        paymentDate:
          payment.bacsPaymentMadeAt || payment.stripePaymentAttemptedAt,
        paymentDateLabel: 'Attempted',
      };
    }, [payment]);

  const timestampDateRegionalFormat = useMemo(
    () =>
      getInternationalDateFormatByRegion(DateFormats.TIMESTAMP_SHORT, region),
    [region]
  );

  const confirmPaymentFailed = () => {
    setIsConfirmingFailed(true);
    dispatch(
      jobPaymentsActions.requestConfirmJobPayment(
        {
          jobPaymentId: paymentId,
          isPaymentReceived: false,
        },
        () => {
          setShowConfirmFailedPaymentModal(false);
          setIsConfirmingFailed(false);
          onConfirmPaymentSuccess?.();
        }
      )
    );
  };

  const quickActions = useMemo(() => {
    const quickActions = [];

    if (
      payment?.bacsPaymentMadeAt &&
      !payment.paymentFailedConfirmationAt &&
      !payment.paymentCompletedConfirmationAt &&
      userHasRequiredPermission(userRoles, [
        PaymentsPermissions.CONFIRM_PAYMENT,
      ])
    ) {
      quickActions.push({
        actionName: 'Confirm payment',
        actionBehaviour: () => setShowConfirmPaymentModal(true),
      });
    }

    if (
      payment?.paymentMethod != PaymentMethodType.STRIPE &&
      payment?.paymentCompletedConfirmationAt &&
      userHasRequiredPermission(userRoles, [
        PaymentsPermissions.CONFIRM_PAYMENT,
      ])
    ) {
      quickActions.push({
        actionName: 'Payment not received',
        actionBehaviour: () => setShowConfirmFailedPaymentModal(true),
      });
    }

    return quickActions;
  }, [payment, userRoles]);

  if (!payment) return null;

  return (
    <>
      <EntityCard
        className="payment-card"
        quickActionsConfig={{
          recordId: paymentId,
          renderDisabledIfNoActions: true,
          quickActions: quickActions,
        }}
      >
        <ResponsiveViewWrapper
          className="payment-card-content"
          downBreakpointSm={500}
        >
          <>
            <dl>
              <dt>Amount</dt>
              <dd>{currencyPrice(payment.paymentValue, region)}</dd>
            </dl>
            <div className="min-w-[100px] justify-self-center">
              <Badge variant="soft" colour={badgeColour}>
                {paymentStatus || ''}
              </Badge>
            </div>
            <dl>
              <dt>Method</dt>
              <dd>{payment.paymentMethod.replaceAll('_', ' ')}</dd>
            </dl>
            <dl>
              <dt>{paymentDateLabel}</dt>
              <dd>{moment(paymentDate).format(timestampDateRegionalFormat)}</dd>
            </dl>
          </>
        </ResponsiveViewWrapper>
      </EntityCard>
      {payment.paymentMethod == PaymentMethodType.FINANCE_PAYOUT &&
        !!payment.paymentCompletedConfirmationAt && (
          <div className="additional-information">
            <FeedbackMessage
              iconBefore={faCircleInfo}
              message={'Fees may have been deducted from this payment'}
            />
          </div>
        )}
      <ConfirmPaymentModal
        jobPaymentId={paymentId}
        isOpen={!!showConfirmPaymentModal}
        onClose={() => {
          setShowConfirmPaymentModal(false);
        }}
        confirmPaymentCallback={() => {
          setShowConfirmPaymentModal(false);
          onConfirmPaymentSuccess?.();
        }}
      />
      <Modal
        isOpen={showConfirmFailedPaymentModal}
        onClose={() => setShowConfirmFailedPaymentModal(false)}
        actions={
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={() => confirmPaymentFailed()}
            isProcessing={isConfirmingFailed}
          >
            Mark payment as failed
          </Button>
        }
        title={'Are you sure?'}
      >
        <p>You previously marked this payment as having been received. </p>
        <p>If you wish to undo this, please continue.</p>
      </Modal>
    </>
  );
};

export default PaymentCard;
