import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';

import Button from '@payaca/components/button/Button';
import { getTotalCompletedPaymentValueFromJobPayments } from '@payaca/helpers/jobPaymentHelper';

import {
  getSpecificJobTypeText,
  JobTypeSpecificText,
} from '@/helpers/jobHelper';
import { currencyPrice } from '@/helpers/financeHelper';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';

import TextareaField from '@payaca/components/textareaField/TextareaField';
import { Job } from '@payaca/types/jobTypesV2';
import { JobContent } from '@payaca/types/jobContentTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { Customer } from '@payaca/types/customerTypes';
import { useSelector } from '@/api/state';
import { DATE_SHORT_READABLE_FORMAT } from '@payaca/constants/formatConstants';

type Props = {
  emailTemplate: string;
  job: Job;
  jobContent: JobContent;
  jobPayments: JobPayment[];
  customer: Customer;
  onChange: (change: any) => void;
  preButtonEmailText: string;
  postButtonEmailText: string;
};
const CustomerEmailPreview: FC<Props> = ({
  emailTemplate,
  job,
  jobContent,
  jobPayments,
  customer,
  onChange,
  preButtonEmailText,
  postButtonEmailText,
}: Props) => {
  const myProfile = useSelector((state: any) => state.users.myProfile);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const companyName = useMemo(
    () => myProfile?.accounts?.[0]?.companyName,
    [myProfile]
  );

  const logoUrl = useMemo(() => myProfile?.accounts?.[0]?.logoUrl, [myProfile]);

  const jobType = useMemo(() => getSpecificJobTypeText(job.status), [job]);

  const amountDue = useMemo(() => {
    if (jobType === JobTypeSpecificText.INVOICE) {
      const jobTotal = jobContent.total;
      const amountPaid = getTotalCompletedPaymentValueFromJobPayments(
        jobPayments || []
      );
      return `${currencyPrice(jobTotal - amountPaid, account.region)}`;
    }
    return '';
  }, [jobContent, jobPayments, jobType, account]);

  const dueDate = useMemo(() => {
    // due at exists on sent jobs, otherwise use dueInOrValidForDays
    return job.dueAt
      ? moment(job.dueAt).format(DATE_SHORT_READABLE_FORMAT)
      : moment()
          .add(job.dueInOrValidForDays, 'days')
          .format(DATE_SHORT_READABLE_FORMAT);
  }, [job]);

  const jobContact = useMemo(
    () => getJobContactFromCustomer(customer, job.contactId),
    [customer]
  );

  const email = useMemo(() => {
    let email = emailTemplate
      ?.replaceAll('[customer_name]', jobContact?.name || '')
      .replaceAll('[reference]', job.customReference || String(job.reference))
      .replaceAll('[company_name]', companyName);
    if (jobType === JobTypeSpecificText.INVOICE) {
      email = email
        ?.replaceAll('[amount_due]', amountDue)
        .replaceAll('[due_date]', dueDate);
    }
    return email || '';
  }, [
    amountDue,
    companyName,
    dueDate,
    emailTemplate,
    jobContact,
    jobType,
    job,
  ]);

  useEffect(() => {
    // set email from email template
    let splitEmail;
    if (email.includes('\n[button]\n')) {
      splitEmail = email.split('\n[button]\n');
    } else {
      splitEmail = email.split('[button]');
    }
    onChange({
      preButtonEmailText: splitEmail[0],
      postButtonEmailText: splitEmail[1],
    });
  }, [email]);

  return (
    <div className="customer-email-preview-wrapper">
      <div className="customer-email-preview">
        {logoUrl && <img className="logo" src={logoUrl} />}
        {/* Pre button text */}
        <TextareaField
          name="preButtonEmailText"
          value={preButtonEmailText}
          onChange={(change: any) => onChange(change)}
          autoSize={true}
        />

        {/* View job button */}
        <Button isDisabled={true}>View {jobType}</Button>

        {/* Post button text */}
        <TextareaField
          name="postButtonEmailText"
          value={postButtonEmailText}
          onChange={(change: any) => onChange(change)}
          autoSize={true}
        />
      </div>
    </div>
  );
};

export default CustomerEmailPreview;
