import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import { useQueryClient } from '@tanstack/react-query';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import ChangeProposalStatusBadge from '../changeProposalStatusBadge/ChangeProposalStatusBadge';
import { AlertTooltip } from '@payaca/components/iconTooltip/IconTooltip';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { getDealValueForChangeProposal } from '@payaca/helpers/changeProposalHelper';

import { getActiveJobContentByDealId, getRegion } from '@/utils/stateAccessors';

import {
  useChangeProposal,
  useChangeProposalsForDeal,
} from '@payaca/store/hooks/appState';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import {
  markChangeProposalAccepted,
  markChangeProposalDeclined,
  requestGetChangeProposal,
} from '@payaca/store/proposals/proposalsActions';

import { useSelector } from '@/api/state';

import './ChangeProposalCard.sass';
import projectKeys from '@/api/queries/project/keyFactory';

interface Props {
  changeProposalId: number;
  onClick?: () => void;
}

const ChangeProposalCard: FC<PropsWithChildren<Props>> = ({
  changeProposalId,
  onClick,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const changeProposal = useChangeProposal(changeProposalId);

  const activeJobContent = useSelector((state) => {
    return changeProposal?.dealId
      ? getActiveJobContentByDealId(state, changeProposal?.dealId)
      : undefined;
  });
  const changeProposals = useChangeProposalsForDeal(changeProposal?.dealId);
  const [isMarkingAsAccepted, setIsMarkingAsAccepted] = useState(false);
  const [isMarkingAsDeclined, setIsMarkingAsDeclined] = useState(false);

  const region = useSelector(getRegion);
  const shortDateRegionalFormat = useMemo(
    () => getInternationalDateFormatByRegion(DateFormats.SHORT, region),
    [region]
  );

  const isNegativeValueChange = useMemo(() => {
    return (changeProposal?.valueChangeIncTax ?? 0) < 0;
  }, [changeProposal]);

  const handleMarkAsAccepted = useCallback(() => {
    setIsMarkingAsAccepted(true);
    dispatch(
      markChangeProposalAccepted.request(
        changeProposalId,
        () => {
          setIsMarkingAsAccepted(false);
          dispatch(requestGetChangeProposal(changeProposalId));
          if (changeProposal) {
            dispatch(requestGetDeal(changeProposal.dealId));
            queryClient.invalidateQueries({
              queryKey: projectKeys.project(changeProposal.dealId),
            });
          }
        },
        (_err) => setIsMarkingAsAccepted(false)
      )
    );
  }, [changeProposalId, changeProposal?.dealId, queryClient]);

  const handleMarkAsDeclined = useCallback(() => {
    setIsMarkingAsDeclined(true);
    dispatch(
      markChangeProposalDeclined.request(
        changeProposalId,
        () => {
          setIsMarkingAsDeclined(false);
          dispatch(requestGetChangeProposal(changeProposalId));
          if (changeProposal) {
            dispatch(requestGetDeal(changeProposal.dealId));
            queryClient.invalidateQueries({
              queryKey: projectKeys.project(changeProposal.dealId),
            });
          }
        },
        (_err) => setIsMarkingAsDeclined(false)
      )
    );
  }, [changeProposalId, changeProposal?.dealId, queryClient]);

  const quickActions = useMemo(() => {
    if (changeProposal?.voidedAt) return [];
    const qa: {
      actionName: string;
      actionBehaviour: (recordId: any) => void;
      isActionProcessing?: boolean | undefined;
    }[] = [];

    if (!changeProposal?.sentAt && !changeProposal?.voidedAt) {
      qa.push({
        actionName: 'Send',
        actionBehaviour: () =>
          history.push(
            `/deals/${changeProposal?.dealId}/changeproposals/${changeProposalId}/edit/send`
          ),
      });
    }
    if (
      !!changeProposal?.sentAt &&
      !changeProposal?.acceptedAt &&
      !changeProposal?.declinedAt
    ) {
      qa.push({
        actionName: 'Mark as accepted',
        actionBehaviour: handleMarkAsAccepted,
        isActionProcessing: isMarkingAsAccepted,
      });
      qa.push({
        actionName: 'Mark as declined',
        actionBehaviour: handleMarkAsDeclined,
        isActionProcessing: isMarkingAsDeclined,
      });
    }

    return qa;
  }, [
    changeProposal,
    changeProposalId,
    isMarkingAsAccepted,
    isMarkingAsDeclined,
  ]);

  if (!changeProposal) return null;

  const priceElement = () => {
    if (changeProposal.acceptedAt) {
      // accepted
      return (
        <>
          <dt>Accepted total {`(inc tax)`}</dt>
          <dd>
            {currencyPrice(
              getDealValueForChangeProposal(
                activeJobContent,
                changeProposals,
                changeProposalId
              ),
              region
            )}
          </dd>
        </>
      );
    } else {
      // proposed
      return (
        <>
          <dt>Proposed change {`(inc tax)`}</dt>
          <dd className={isNegativeValueChange ? 'negative' : 'positive'}>
            {isNegativeValueChange ? '-' : '+'}
            {currencyPrice(Math.abs(changeProposal.valueChangeIncTax), region)}
          </dd>
        </>
      );
    }
  };

  const dateElement = () => {
    if (changeProposal.expiresAt && !changeProposal.acceptedAt) {
      return (
        <>
          <dt>Valid until</dt>
          <dd>
            <time dateTime={changeProposal?.expiresAt?.toString()}>
              {moment(changeProposal.expiresAt).format(shortDateRegionalFormat)}
            </time>
          </dd>
        </>
      );
    } else if (changeProposal.acceptedAt) {
      return (
        <>
          <dt>Accepted at</dt>
          <dd>
            <time dateTime={changeProposal?.acceptedAt?.toString()}>
              {moment(changeProposal.acceptedAt).format(
                shortDateRegionalFormat
              )}
            </time>
          </dd>
        </>
      );
    }
  };

  const issue = useMemo(() => {
    if (changeProposal.bouncedAt) {
      return 'E-mail has bounced';
    }
  }, [changeProposal]);

  return (
    <>
      <EntityCard
        className="change-proposal-card"
        onClick={onClick}
        quickActionsConfig={{
          recordId: changeProposalId,
          renderDisabledIfNoActions: true,
          quickActions: quickActions,
        }}
      >
        <ResponsiveViewWrapper
          className="change-proposal-card-content"
          downBreakpointSm={500}
        >
          <>
            <ChangeProposalStatusBadge status={changeProposal.readableStatus} />
            <dl className="price">{priceElement()}</dl>
            <dl className="date">{dateElement()}</dl>
            {issue && (
              <div className={'card-issue'}>
                <AlertTooltip tooltipText={issue} />
              </div>
            )}
          </>
        </ResponsiveViewWrapper>
      </EntityCard>
    </>
  );
};

export default ChangeProposalCard;
