import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { currencyPrice } from '@payaca/helpers/financeHelper';

import { getRegion } from '@/utils/stateAccessors';

import {
  usePaymentsForDeal,
  useInvoicesForDeal,
  usePaymentReconciliationRecordsForDeal,
} from '@payaca/store/hooks/appState';

import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';

import { JobPayment } from '@payaca/types/jobPaymentTypes';

import { useSelector } from '@/api/state';

import './DealPaymentReconciliationTable.sass';

interface Props {
  dealId: number;
}

const DealPaymentReconciliationTable: FC<PropsWithChildren<Props>> = ({
  dealId,
}) => {
  const payments = usePaymentsForDeal(dealId);
  const paymentReconciliationRecords =
    usePaymentReconciliationRecordsForDeal(dealId);
  const invoices = useInvoicesForDeal(dealId);

  const region = useSelector((state) => getRegion(state));
  const shortTimestampRegionalFormat = useMemo(
    () =>
      getInternationalDateFormatByRegion(DateFormats.TIMESTAMP_SHORT, region),
    [region]
  );

  const successfulPayments = useMemo(() => {
    return payments.filter((x) => !!x.paymentCompletedConfirmationAt);
  }, [payments]);

  const renderTableRowsForPayment = useCallback(
    (payment: JobPayment, i: number) => {
      const reconciliationRecords = paymentReconciliationRecords.filter(
        (x) => x.jobPaymentId === payment.id
      );
      const reconciliationRecordCount = reconciliationRecords.length;

      return (
        <tbody key={i}>
          {reconciliationRecords.map((reconciliationRecord, i2) => {
            const invoice = invoices.find(
              (x) => x.id === reconciliationRecord.invoiceId
            );
            return (
              <tr key={i2}>
                {i2 === 0 && (
                  <>
                    <td rowSpan={reconciliationRecordCount} className="date">
                      {moment(payment.paymentCompletedConfirmationAt).format(
                        shortTimestampRegionalFormat
                      )}
                    </td>
                    <td
                      rowSpan={reconciliationRecordCount}
                      className="payment-method"
                    >
                      {payment.paymentMethod.replaceAll('_', ' ')}
                    </td>
                    <td
                      rowSpan={reconciliationRecordCount}
                      className="payment-value"
                    >
                      <strong>
                        {currencyPrice(payment.paymentValue, region)}
                      </strong>
                    </td>
                  </>
                )}
                <td className="reconciliation-value">
                  {currencyPrice(reconciliationRecord.value, region)}
                </td>
                <td className="reconciliation-arrow">
                  <FontAwesomeIcon icon={faArrowRight} />
                </td>
                <td className="reconciliation-target">
                  {reconciliationRecord.invoiceId ? (
                    <>
                      Invoice {invoice?.customReference || invoice?.reference}
                    </>
                  ) : (
                    <>Unreconciled</>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    },
    [
      shortTimestampRegionalFormat,
      invoices,
      region,
      paymentReconciliationRecords,
    ]
  );

  if (!successfulPayments?.length) return null;

  return (
    <table className="deal-payment-reconciliation-table">
      <thead>
        <th className="date">Date</th>
        <th className="payment-method">Method</th>
        <th className="payment-value">Value</th>
        <th colSpan={3} className="reconciliation">
          Reconciliation
        </th>
      </thead>
      {successfulPayments.map(renderTableRowsForPayment)}
    </table>
  );
};

export default DealPaymentReconciliationTable;
