import { FC, useMemo } from 'react';
import './DealActivityEvent.sass';
import { format } from 'date-fns';

import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';
import { isValidHtml } from '@payaca/utilities/htmlUtilities';
import { DealActivityEventType } from '@payaca/types/dealActivityTypes';
import { TIconName } from '@payaca/untitled-icons';
import TruncatedHtml from '@payaca/components/TruncatedHTML/TruncatedHTML';

import {
  IntegratedEmailProjectActivityEvent,
  ProjectActivityEvent,
} from '@/gql/graphql';
import DealActivityEventPrimaryAction from './DealActivityEventPrimaryAction';
import DealActivityCard from '@/ui/components/dealActivityCard/DealActivityCard';
import { useHistory } from 'react-router-dom';

type Props = {
  projectActivityEvent:
    | ProjectActivityEvent
    | IntegratedEmailProjectActivityEvent;
  projectId: string;
};

const DealActivityEvent: FC<Props> = (props) => {
  const { projectActivityEvent, projectId } = props;
  const history = useHistory();

  const iconName = useMemo<TIconName>(() => {
    switch (projectActivityEvent.type as DealActivityEventType) {
      case 'changeProposalSent':
      case 'proposalSent':
      case 'invoiceSent':
      case 'invoicePaymentReminderSent':
      case 'purchaseOrderSent':
      case 'emailSendAttempted':
      case 'integratedEmailSendAttempted':
      case 'integratedEmailReceived':
      case 'smsSendAttempted':
        return 'mail-02.3';
      case 'proposalViewed':
      case 'changeProposalViewed':
      case 'invoiceViewed':
        return 'eye.3';
      case 'proposalDeclined':
      case 'changeProposalDeclined':
        return 'x-close';
      case 'changeProposalAccepted':
      case 'proposalAccepted':
        return 'check';
      case 'paymentCompleted':
        return 'currency-pound';
      case 'automationActionRan':
        return 'plus-circle.3';
      case 'note':
        return 'edit-01.3';
      case 'changeProposalVoided':
      case 'invoiceVoided':
      case 'changeProposalExpired':
      case 'proposalExpired':
      case 'invoiceOverdue':
        return 'file-x-01.3';
      case 'taskMarkedComplete':
        return 'check-circle.3';
      default:
        return 'info-circle.3';
    }
  }, [projectActivityEvent]);

  const willHaveLongDescription = useMemo(() => {
    switch (projectActivityEvent.type as DealActivityEventType) {
      case 'note':
      case 'emailSendAttempted':
      case 'smsSendAttempted':
      case 'integratedEmailSendAttempted':
      case 'integratedEmailReceived':
        return true;
      default:
        return false;
    }
  }, [projectActivityEvent]);

  const isHtml = useMemo(() => {
    return isValidHtml(projectActivityEvent.description);
  }, [projectActivityEvent.description]);

  return (
    <DealActivityCard
      onClick={
        projectActivityEvent.type === 'note'
          ? () =>
              history.push(
                `/deals/${projectId}/activity/${projectActivityEvent.linkedEntity?.id}`
              )
          : undefined
      }
      iconName={iconName}
      whiteSpacePreWrap={isHtml}
      title={
        willHaveLongDescription ? (
          <p className="font-semibold">{projectActivityEvent.name}</p>
        ) : (
          <MarkdownLabel markdown={projectActivityEvent.description} />
        )
      }
      titleSuffix={
        <>
          <DealActivityEventPrimaryAction
            projectActivityEvent={projectActivityEvent}
          />

          <span className="whitespace-nowrap">
            {format(projectActivityEvent.timestamp, 'E d MMM H:mm')}
          </span>
        </>
      }
    >
      {willHaveLongDescription &&
        (isHtml ? (
          <TruncatedHtml
            className="deal-activity-event-html-description"
            html={projectActivityEvent.description}
          />
        ) : (
          <MarkdownLabel
            truncatedClassName="container-bottom-shadow relative"
            limitLines={8}
            markdown={projectActivityEvent.description}
            truncate
          />
        ))}
    </DealActivityCard>
  );
};

export default DealActivityEvent;
