import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import InvoiceStatusBadge from '../invoiceStatusBadge/InvoiceStatusBadge';
import RecordPaymentModal from '../recordPaymentModal/RecordPaymentModal';
import { AlertTooltip } from '@payaca/components/iconTooltip/IconTooltip';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';

import { getRegion } from '@/utils/stateAccessors';

import { useInvoice } from '@payaca/store/hooks/appState';

import { useSelector } from '@/api/state';

import './InvoiceCard.sass';
import { useDispatch } from 'react-redux';
import { pushInvoiceToAccountingIntegrations } from '@payaca/store/invoices/invoicesActions';

interface Props {
  invoiceId: number;
  onClick?: () => void;
  onRecordPaymentSuccess?: () => void;
}

const InvoiceCard: FC<PropsWithChildren<Props>> = ({
  invoiceId,
  onClick,
  onRecordPaymentSuccess,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const invoice = useInvoice(invoiceId);
  const region = useSelector((state) => getRegion(state));
  const [showRecordPaymentModal, setShowRecordPaymentModal] = useState(false);
  const shortDateRegionalFormat = useMemo(
    () => getInternationalDateFormatByRegion(DateFormats.SHORT, region),
    [region]
  );

  const quickActions = useMemo(() => {
    if (invoice?.voidedAt) return [];
    const qa: {
      actionName: string;
      actionBehaviour: (recordId: any) => void;
      isActionProcessing?: boolean | undefined;
    }[] = [];

    if (!invoice?.sentAt && !invoice?.voidedAt) {
      qa.push({
        actionName: 'Send',
        actionBehaviour: () =>
          history.push(
            `/deals/${invoice?.dealId}/invoices/${invoiceId}/edit/send`
          ),
      });
    }

    if (!!invoice?.sentAt && invoice.dueValue > 0) {
      qa.push({
        actionName: 'Record a payment',
        actionBehaviour: () => setShowRecordPaymentModal(true),
      });
    }

    if (
      !!invoice?.sentAt &&
      !invoice.voidedAt &&
      (!invoice.quickbooksInvoiceId || !invoice.xeroInvoiceId)
    ) {
      qa.push({
        actionName: 'Push to accounting software',
        actionBehaviour: () => {
          dispatch(
            pushInvoiceToAccountingIntegrations.request({
              invoiceId: invoice.id,
            })
          );
        },
      });
    }

    return qa;
  }, [invoice, invoiceId]);

  const issue = useMemo(() => {
    if (invoice?.bouncedAt) {
      return 'E-mail has bounced';
    }
  }, [invoice]);

  if (!invoice) return null;

  return (
    <>
      <EntityCard
        className="invoice-card"
        onClick={onClick}
        quickActionsConfig={{
          recordId: invoiceId,
          renderDisabledIfNoActions: true,
          quickActions: quickActions,
        }}
      >
        <ResponsiveViewWrapper
          className="invoice-card-content"
          downBreakpointSm={500}
        >
          <>
            <dl>
              <dt>Invoice</dt>
              <dd>{invoice.customReference || invoice.reference}</dd>
            </dl>
            <div className="status-and-warning-wrapper">
              {issue && (
                <div className={'card-issue'}>
                  <AlertTooltip tooltipText={issue} />
                </div>
              )}
              <InvoiceStatusBadge status={invoice.readableStatus} />
            </div>
            <dl>
              <dt>Total</dt>
              <dd>{currencyPrice(invoice.totalValue, region)}</dd>
            </dl>
            <dl>
              <dt>Outstanding</dt>
              <dd>{currencyPrice(invoice.dueValue, region)}</dd>
            </dl>
            <dl className="due">
              <dt>Due</dt>
              <dd>
                {invoice.dueAt && (
                  <time dateTime={invoice.dueAt.toString()}>
                    {moment(invoice.dueAt).format(shortDateRegionalFormat)}
                  </time>
                )}
              </dd>
            </dl>
          </>
        </ResponsiveViewWrapper>
      </EntityCard>
      <RecordPaymentModal
        dealId={invoice.dealId}
        paymentTarget={{
          invoiceId: invoiceId,
        }}
        isOpen={showRecordPaymentModal}
        onClose={() => setShowRecordPaymentModal(false)}
        recordPaymentCallback={onRecordPaymentSuccess}
        confirmPaymentCallback={onRecordPaymentSuccess}
      />
    </>
  );
};

export default InvoiceCard;
