import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  ScheduledEvent,
  SimpleEventContactWithRoles,
} from '@payaca/types/scheduledEventsTypes';
import { SendScheduledEventConfirmationData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { useAccount } from '@/utils/storeHooks';
import {
  DateFormats,
  getInternationalDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import moment from 'moment-timezone';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import {
  getIsRequiredFieldValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { COMMA_SEPARATED_EMAIL_LIST } from '@payaca/constants/regexConstants';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import { useDispatch } from 'react-redux';
import { sendScheduledEventConfirmation } from '@payaca/store/scheduledEvents/scheduledEventsActions';
import Select, { SelectOption } from '@payaca/components/plSelect/Select';
import Modal from '@payaca/components/plModal/Modal';

const fieldValidators = {
  to: [
    getIsRequiredFieldValidator({}),
    getRegexMatchFieldValidator(COMMA_SEPARATED_EMAIL_LIST),
  ],
  emailSubject: [getIsRequiredFieldValidator({ readableName: 'subject' })],
  emailBody: [getIsRequiredFieldValidator({ readableName: 'message' })],
};

export const SendEventConfirmationControl: FC<{
  onSuccess?: () => void;
  onCancel?: () => void;
  scheduledEvent: ScheduledEvent;
  contacts: SimpleEventContactWithRoles[];
}> = ({ onSuccess, onCancel, scheduledEvent, contacts }) => {
  const account = useAccount();
  const region = account.region;
  const companyName = useMemo(() => account?.companyName, [account]);
  const timestampShortDelimtedDateRegionalFormat = useMemo(
    () => getInternationalDateFormatByRegion(DateFormats.MID, region),
    [region]
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showValidationErrorsWarning, setShowValidationErrorsWarning] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const dispatch = useDispatch();

  const initialFormState: SendScheduledEventConfirmationData = useMemo(() => {
    const subjectElements: string[] = [];

    if (scheduledEvent.dealReference?.length) {
      subjectElements.push(scheduledEvent.dealReference);
    }

    subjectElements.push(scheduledEvent.name);

    const addressAsString = scheduledEvent.location?.address
      ? getAddressAsString(scheduledEvent.location?.address)
      : '';
    if (addressAsString?.length) {
      subjectElements.push(addressAsString);
    }

    return {
      scheduledEventId: scheduledEvent.id,
      to: scheduledEvent.contactsToNotify
        .filter((c) => c.emailAddress?.length)
        .map((c) => c.emailAddress) as string[],
      emailSubject: `Ref: ${subjectElements.join(' - ')}`,
      emailBody: `Booking confirmed for ${moment(scheduledEvent.beginAt).format(
        `dddd Do MMMM [at] hh:mma`
      )}${
        addressAsString?.length
          ? ` at the following address:\n\n${addressAsString}`
          : '.'
      }\n\nThanks,\n\n${companyName}`,
    };
  }, []);

  const onSubmit = useCallback((data: SendScheduledEventConfirmationData) => {
    setIsSubmitting(true);
    setErrorMessage(undefined);
    dispatch(
      sendScheduledEventConfirmation.request({
        data,
        callback: () => {
          setIsSubmitting(false);
          onSuccess?.();
        },
        onErrorCallback: () => {
          setIsSubmitting(false);
          setErrorMessage('Failed to send confirmation. Something went wrong.');
        },
      })
    );
  }, []);

  const contactOptions: SelectOption<string, SimpleEventContactWithRoles>[] =
    useMemo(() => {
      return contacts
        .filter((x) => x.emailAddress?.length)
        .map((x) => {
          return {
            value: x.emailAddress || '',
            label: x.name || '',
            metadata: x,
          };
        });
    }, [contacts]);

  return (
    <ValidatedForm<SendScheduledEventConfirmationData>
      initialFormState={initialFormState}
      fieldValidators={fieldValidators}
      renderFormContents={(
        isValid,
        formState,
        validationState,
        touchedState,
        onFieldChange,
        onFieldTouch
      ) => {
        return (
          <>
            <Modal.Body className="flex flex-col gap-4">
              <Field
                name="to"
                validationState={
                  validationState?.['to']?.isValid === false
                    ? {
                        isValid: false,
                        validationMessages: validationState?.['to']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>To</Field.Label>
                <Select
                  value={formState.to}
                  multiple={true}
                  options={contactOptions}
                  onChange={(value) => {
                    onFieldChange({ to: value });
                    onFieldTouch('to');
                  }}
                  CustomOption={({ option, close }) => {
                    return (
                      <span>
                        <span>{option.label}</span>
                        {option.metadata?.emailAddress?.length && (
                          <span className="ml-3 text-xs text-gray-500">
                            {option.metadata?.emailAddress}
                          </span>
                        )}
                      </span>
                    );
                  }}
                />
              </Field>
              <Field
                name="emailSubject"
                validationState={
                  validationState?.['emailSubject']?.isValid === false
                    ? {
                        isValid: false,
                        validationMessages:
                          validationState?.['emailSubject']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Subject</Field.Label>
                <Input
                  value={formState.emailSubject}
                  onChange={(value) => {
                    onFieldChange({ emailSubject: value });
                  }}
                  onTouch={() => onFieldTouch('emailSubject')}
                />
              </Field>
              <Field
                name="emailBody"
                validationState={
                  validationState?.['emailBody']?.isValid === false
                    ? {
                        isValid: false,
                        validationMessages:
                          validationState?.['emailBody']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Message</Field.Label>
                <Textarea
                  autoHeight={true}
                  value={formState.emailBody}
                  onChange={(value) => {
                    onFieldChange({ emailBody: value });
                  }}
                  onTouch={() => onFieldTouch('emailBody')}
                />
              </Field>
            </Modal.Body>

            <Modal.Footer className="flex flex-col gap-y-4">
              {showValidationErrorsWarning && !isValid && (
                <Alert colour={EAlertColour.SOFT_RED}>
                  Form cannot be submitted whilst there are validation errors
                </Alert>
              )}
              {errorMessage && (
                <Alert colour={EAlertColour.SOFT_RED}>{errorMessage}</Alert>
              )}
              <Modal.Footer.Actions>
                {onCancel && (
                  <Button variant={EBtnVariant.Outline} onClick={onCancel}>
                    Cancel
                  </Button>
                )}
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    if (isSubmitting) return;
                    if (!isValid) {
                      setShowValidationErrorsWarning(true);
                      onFieldTouch(
                        Object.entries(validationState)
                          .filter(([fieldName, validationResult]) => {
                            return !validationResult.isValid;
                          })
                          .map(([fieldName]) => fieldName)
                      );
                    } else {
                      onSubmit(formState);
                    }
                  }}
                >
                  Send
                </Button>
              </Modal.Footer.Actions>
            </Modal.Footer>
          </>
        );
      }}
    />
  );
};
