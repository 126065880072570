import * as Sentry from '@sentry/react';
import history from '../services/history';

if (import.meta.env.VITE_SENTRY_CLIENT_ID) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_CLIENT_ID,
    environment: import.meta.env.VITE_ENV || import.meta.env.VITE_NODE_ENV,
    ignoreErrors: [/t.getElementsByTagName/],
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  });
}
